import { useState } from 'react';
import {
  Body,
  Box,
  Flex,
  H4,
  SecondaryOutlinedButton,
  PrimaryButton,
  useApi,
  useAuth,
} from '@fivehealth/botero';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useCookies } from 'react-cookie';

import LoadingIndicator from '../assets/loading.gif';
import { useAppData } from '../context/AppDataContext';
import { useModal } from '../context/ModalContext';
import ErrorModal from './ErrorModal';
import ExportModal from './ExportModal';
import FinalSummaryPatient from './FinalSummaryPatient';
import {
  InputSummaryMediSave,
  InputSummaryMediShield,
} from './InputSummaryMedi';
import InputSummaryTreatment from './InputSummaryTreatment';
import RegimenSummary from './RegimenSummary';
import Config from '../Config';
import SendLinkModal from './SendLinkModal';
import InputSummaryPatient from './InputSummaryPatient';
import { UserInfoType, isUserPatient } from '../utils/global';
import InputSummaryMonthlyClaims from './InputSummaryMonthlyClaims';
import ShareModal from './ShareModal';
import FinalSummary from './FinalSummary';

export default function Results() {
  /* eslint-disable-next-line */
  const [cookies] = useCookies([
    Config.cookie.name,
    Config.cookie.patientCalcCostResultSesh,
  ]);

  const { authState } = useAuth();
  const { loading, results, session } = useAppData();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { openModal, closeModal } = useModal();
  const user: UserInfoType = cookies && cookies[Config.cookie.name];
  const [priceUpdatedDate, setPriceUpdatedDate] = useState('');

  const {
    queries: { useRegimens },
  } = useApi({
    queries: ['useRegimens'],
  });
  useRegimens({
    enabled: authState.authenticated,
    staleTime: Infinity,
    onSuccess: ({ data: { header } }: { data: { header: string } }) =>
      setPriceUpdatedDate(header),
    onError: () => openModal(<ErrorModal message="Server not responding" />),
    variables: {
      miniapp: `${user?.hospital?.toLowerCase()}_chemocalc`,
    },
  });

  const handlePatientShareModal = (type: 'email' | 'download') => {
    setAnchorEl(null);
    openModal(<ShareModal closeModal={closeModal} type={type} />);
  };

  const openMenuShareGenPDF = (event: any) => {
    // eslint-disable-next-line no-restricted-globals
    setAnchorEl(event.currentTarget);
  };
  return (
    <Box position="relative">
      {loading ? (
        <Flex
          top={0}
          left={0}
          width="100%"
          height="100%"
          style={{
            position: 'absolute',
            zIndex: 1000,
            backgroundColor: 'rgba(255,255,255,0.75)',
          }}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <img
            style={{ width: '240px', height: '240px' }}
            src={LoadingIndicator}
            alt="Loading spinner indicator"
          />
          <Body fontWight={400} style={{ position: 'relative', top: '-24px' }}>
            Calculating
          </Body>
        </Flex>
      ) : null}
      <Flex
        justifyContent="space-between"
        flexWrap={['wrap', 'nowrap']}
        pt={1}
        mb={2}
        mt={isUserPatient(session) ? 0 : -4}
      >
        {!isUserPatient(session) && (
          <Box width={[600, 550]} mb={2} mt={[1, 0]}>
            <H4 mb={0.5}>Results</H4>{' '}
            <Box>
              <Body maxWidth={500} extraSmall color="darkestShade">
                {
                  priceUpdatedDate
                    ? `Prices updated ${priceUpdatedDate}`
                    : '' /* Added invisible string(U+3164) to prevent bump after date load */
                }
              </Body>
            </Box>
          </Box>
        )}

        <Box width="100%">
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            <MenuItem
              onClick={() => {
                handlePatientShareModal('email');
              }}
            >
              Email to Hospital
            </MenuItem>
            <MenuItem
              onClick={() => {
                handlePatientShareModal('download');
              }}
            >
              Download PDF
            </MenuItem>
          </Menu>

          {isUserPatient(session) ? (
            <Flex justifyContent="space-between" mt={[null, -2]}>
              <Box mb={2} mt={[0, 0]} ml={['43%', '45%']}>
                <Flex justifyContent="center">
                  <H4 mb={0.5}>Results</H4>
                </Flex>
              </Box>
              <Box>
                <PrimaryButton
                  ml={2}
                  style={{ fontWeight: 500, borderRadius: '8px' }}
                  onClick={openMenuShareGenPDF}
                >
                  Share
                </PrimaryButton>
              </Box>
            </Flex>
          ) : (
            <Box mb={0}>
              <Flex justifyContent={['stretch', 'end']}>
                <SecondaryOutlinedButton
                  disabled={results.length === 0}
                  style={{ fontWeight: 500, borderRadius: '8px' }}
                  onClick={() => {
                    openModal(<ExportModal closeModal={closeModal} />);
                  }}
                  width="100%"
                >
                  Export Results
                </SecondaryOutlinedButton>
                <PrimaryButton
                  ml={2}
                  disabled={results.length === 0}
                  style={{ fontWeight: 500, borderRadius: '8px' }}
                  onClick={() => {
                    openModal(<SendLinkModal closeModal={closeModal} />);
                  }}
                  width="100%"
                >
                  Send link to patient
                </PrimaryButton>
              </Flex>
            </Box>
          )}
        </Box>
      </Flex>

      <Box borderRadius={8} mb={2} backgroundColor="#FEF7ED" p={2}>
        <InputSummaryPatient user={user} />
      </Box>
      {
        // NOTE: As per figma design we dont need this section
        //   !isUserPatient(session) && (
        //   <Box borderRadius={8} mt={2} mb={2} backgroundColor="#EBFAF1" p={2}>
        //     <InputSummarySubsidy user={user} />
        //   </Box>
        // )
      }
      <Box mb={2}>
        <RegimenSummary isUserPatient={isUserPatient(session)} />
        {isUserPatient(session) ? <FinalSummaryPatient /> : <FinalSummary />}
      </Box>
      <Box borderRadius={8} mb={2} backgroundColor="#E9F0FE" p={2}>
        <InputSummaryTreatment user={user} />
      </Box>

      {isUserPatient(session) && (
        <>
          <Box borderRadius={8} mb={2} backgroundColor="#EBFAF1" p={2}>
            <InputSummaryMediShield />
          </Box>
          <Box borderRadius={8} mb={2} backgroundColor="#EBFAF1" p={2}>
            <InputSummaryMediSave />
          </Box>
          <Box borderRadius={8} mb={2} backgroundColor="#E9F0FE" p={2}>
            <InputSummaryMonthlyClaims />
          </Box>
          {
            // NOTE: We remove yearly claim section coz we dont need it
            // <Box borderRadius={8} mb={2} backgroundColor="#F4F6F8" p={2}>
            //   <InputSummaryYearlyClaims />
            // </Box>
          }
        </>
      )}

      <Box mt={2} mb="20px">
        <Body fontSize="12px" fontWeight={400} color="fullShade">
          List price refers to prices before any subsidies.
          <br />
          Subsidies include any eligible subsidies such as SDL, MAF, PG, MG.
          <br />
          MediShield Life and Medisave claims are automatically applied to SDL
          drugs in the MOH Cancer Drug List.
          <br />
          <br />
          This tool estimates costs for typical regimens and does not include
          services costs.
          <Box display={[results.length ? 'block' : 'none', 'none']}>
            <br />
            <br />
            If you face issues, please contact{' '}
            <a
              style={{ color: '#256BF6', textDecoration: 'underline' }}
              href="mailto:customersupport@botmd.io?subject=ChemoCalc v2"
              target="_blank"
              rel="noopener noreferrer"
            >
              customersupport@botmd.io
            </a>
            .
          </Box>
          <br />
          <br />
          MOH ChemoCost Version 1.0 February 2024
        </Body>
      </Box>
    </Box>
  );
}
