import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const HEIMDALL_AUTHORIZATION_FLOW = gql`
  mutation heimdallAuthorizationFlow($input: HeimdallAuthorizationFlowInput!) {
    heimdallAuthorizationFlow(input: $input) {
      redirectTo
      session {
        id
        uid
        scopes
        expiresOn
        user {
          uid
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: HEIMDALL_AUTHORIZATION_FLOW,
  queryType: 'mutation',
  baseQueryKey: 'heimdallAuthorizationFlow',
  keepPreviousData: true,
});
