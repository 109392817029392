import { Box, FAIcon, Flex, H5, H6, Text, Tooltip } from '@fivehealth/botero';
import { faBook, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useModal } from '../../context/ModalContext';
import CDLIndicationModal from './CDLIndication.modal';

function Label(props: any) {
  return <H6 color="darkestShade" {...props} />;
}

function DrugIndicationRadio({
  label,
  indicator = false,
  onChange,
}: {
  indicator: boolean;
  label: string;
  onChange: (value: string) => void;
}) {
  return (
    <Box my={1}>
      <Flex flexDirection="column" flex="0 0 50%" alignItems="start">
        <Tooltip
          tooltip={
            <Text color="white">
              Refer to MOH Cancer Drug list to view MAF drugs and the MAF
              approved indications
            </Text>
          }
          toolTipOptions={{
            placement: 'top',
          }}
          toolTipElementProps={{
            maxWidth: 275,
            backgroundColor: '#000 !important',
            px: 2,
          }}
          style={{
            display: 'flex',
            alignItems: 'baseline',
          }}
        >
          <Label>{label}</Label>
          <FAIcon
            icon={faQuestionCircle}
            hover={{ opacity: 0.6 }}
            fontSize={10}
            style={{
              position: 'relative',
              cursor: 'pointer',
              left: '6px',
              top: '0px',
            }}
          />
        </Tooltip>
      </Flex>
      <Box>
        <FormControl disabled={false}>
          <RadioGroup
            row
            aria-labelledby={label}
            value={indicator}
            sx={{
              zIndex: 0,
            }}
            name={label}
            onChange={(e) => onChange(e.target.value)}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
}

function IndicationBox({
  drug,
  maf,
  cdl,
  indications,
  updateIndications,
}: {
  drug: string;
  maf: boolean;
  cdl: boolean;
  indications: string;
  updateIndications: (indications: any) => void;
}) {
  const { openModal, closeModal } = useModal();

  const handleIconClick = () => {
    openModal(
      <CDLIndicationModal
        drug={drug}
        body={indications}
        closeModal={closeModal}
      />,
      { style: { width: '720px' } }
    );
  };

  const onChange = (indicator: string) => (value: string) => {
    const indication = {
      drug,
      maf,
      cdl,
      [indicator]: value === 'true',
      indications,
    };
    updateIndications(indication);
  };

  return (
    <Box border="1px solid #D5D7DE" borderRadius={8} mt={2}>
      <Flex justifyContent="space-between" py={1} px={2}>
        <H5 pr={1}>{drug}</H5>
        <FAIcon
          icon={faBook}
          style={{ cursor: 'pointer' }}
          onClick={handleIconClick}
        />
      </Flex>
      <Box borderTop="1px solid #D5D7DE" px={2} py={1}>
        {cdl !== null && (
          <DrugIndicationRadio
            indicator={cdl}
            label="CDL Indication (MSHL Claimable)?"
            onChange={onChange('cdl')}
          />
        )}

        {maf !== null && (
          <DrugIndicationRadio
            onChange={onChange('maf')}
            indicator={maf}
            label="MAF-Approved Indication?"
          />
        )}
      </Box>
    </Box>
  );
}

export default IndicationBox;
