import { Box, Flex } from '@fivehealth/botero';
import React from 'react';
import Lottie from 'react-lottie-player';
import settingsLoader from '../assets/botmd-potato.json';

export type AnimatedLoadingLottieProps = {
  flexProps?: React.ComponentProps<typeof Flex>;
  textProps?: React.ComponentProps<typeof Box>;
  width?: number;
  text?: React.ReactNode | string;
};

const AnimatedLoadingLottie: React.FunctionComponent<
  AnimatedLoadingLottieProps
  // eslint-disable-next-line react/function-component-definition
> = ({ text, flexProps, textProps, width = 300 }) => (
  <Flex
    width="100%"
    height="100%"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    mt={150}
    {...flexProps}
  >
    <Lottie play animationData={settingsLoader} style={{ width }} />
    <Box
      fontSize={18}
      fontWeight={600}
      style={{ marginTop: -80 }}
      {...textProps}
    >
      {text}
    </Box>
  </Flex>
);

export default AnimatedLoadingLottie;
