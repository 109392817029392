import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_USER_PROFILE_SGID = gql`
  query heimdallSgidUserInfo {
    heimdallSgidUserInfo {
      userInfo
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_USER_PROFILE_SGID,
  queryType: 'query',
  baseQueryKey: 'currentSession',
  keepPreviousData: false,
});
