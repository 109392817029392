import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_USER_PROFILE_HOSPITAL = gql`
  query currentSession {
    hospitalProfile {
      uid
      firstName
      lastName
      fullName
      department
      designation
      settings
      hospital {
        shortName
        name
        #        logicSettings
        #        settings
        #        organization
      }
    }
    hospitalDirectoryProfiles {
      allUids
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_USER_PROFILE_HOSPITAL,
  queryType: 'query',
  baseQueryKey: 'currentSessionHospital',
  keepPreviousData: false,
});
