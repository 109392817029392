import { Box, Flex, useAuth, Badge } from '@fivehealth/botero';
import { ReactNode, useCallback, useEffect } from 'react';
import {
  Route as ReactRouterRoute,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { isEmpty } from 'lodash';

import { GraphQLClient } from 'graphql-request';
import { useCookies } from 'react-cookie';
import { useAppData } from '../context/AppDataContext';
import PatientDetails from './patientDetails';
import Regimen from './Regimen';
import Results from './Results';
import Config from '../Config';
import { GRAPHQL_DOCUMENT_SESS_BYUID } from '../api/queries/useHeimdall';
import { isUserPatient } from '../utils/global';

interface Props {
  children?: ReactNode;
  path?: string;
}

function CustomRoute({ children, path }: Props) {
  const {
    authState: { authenticated },
  } = useAuth();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!authenticated) {
      history.push(`/login?hospital=${location.pathname.replace('/', '')}`);
    }
  }, [authenticated]);

  return (
    <ReactRouterRoute
      path={path}
      render={() => (authenticated ? children : null)}
    />
  );
}

function AuthCallback() {
  return null;
}

function CustomRoutes({ loggedIn }: { loggedIn: boolean }) {
  const [cookies] = useCookies([
    Config.cookie.name,
    Config.cookie.patientCalcCostResultSesh,
  ]);
  const user = cookies && cookies[Config.cookie.name];

  const costResultCookies =
    cookies[`${Config.cookie.patientCalcCostResultSesh}`];

  const { results, setSession, session } = useAppData();
  const handleSessionScope = useCallback(() => {
    const apiClient = new GraphQLClient(Config.HIPPO_GQL_ENDPOINT, {
      headers: {
        'X-SESSION': user?.token,
      },
    });
    apiClient
      .request(GRAPHQL_DOCUMENT_SESS_BYUID)
      .then(({ heimdallSession }) => {
        setSession(heimdallSession);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (isEmpty(session)) {
      handleSessionScope();
    }
  }, [handleSessionScope]);

  return (
    <Switch>
      <ReactRouterRoute exact path="/auth/callback">
        <AuthCallback />
      </ReactRouterRoute>
      <CustomRoute path="/">
        {loggedIn && (
          <Flex mt={24} flexDirection={['column', 'row']}>
            <Box
              flex={1}
              pb={5}
              mb={[5, 0]}
              borderBottom={['1px solid #D5D7DE', 'none']}
              mr={[0, '16px']}
              style={{
                display: !isEmpty(costResultCookies) ? 'none' : null,
              }}
            >
              <Regimen />
              <PatientDetails />
              <Box
                display={[results.length ? 'none' : 'flex', 'flex']}
                mt={4}
                width="calc(100% - 2px)"
              >
                <Badge
                  bg="lightestShade"
                  color="darkestShade"
                  borderRadius={10}
                  ml={0}
                  p={2}
                >
                  🐛 Facing issues or bugs with ChemoCost? Let us know at{' '}
                  <a
                    style={{ color: '#256BF6', textDecoration: 'underline' }}
                    href="mailto:customersupport@botmd.io?subject=ChemoCalc v2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    customersupport@botmd.io
                  </a>
                </Badge>
              </Box>
            </Box>

            <Box
              flex={2}
              borderRadius={12}
              p={[isUserPatient(session) ? 2 : 0, 4]}
              backgroundColor="white"
              style={{
                border: isUserPatient(session) ? '1px solid #D5D7DE' : null,
              }}
              maxWidth={isUserPatient(session) ? 720 : 'auto'}
              ml={[0, isUserPatient(session) ? 140 : 0]}
            >
              <Results />
            </Box>
          </Flex>
        )}
      </CustomRoute>
    </Switch>
  );
}

export default CustomRoutes;
