import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import {
  ApiProvider,
  AuthProvider,
  Flex,
  ThemeProvider,
  useAuth,
} from '@fivehealth/botero';

import { GraphQLClient } from 'graphql-request';
import { GRAPHQL_DOCUMENT_USER_PROFILE_HOSPITAL } from './api/queries/useCurrentSessionHospital';

import Content from './components/Content';
import PatientRetrieveInfo from './components/PatientRetrieveInfo';
import Config from './Config';

import { GRAPHQL_DOCUMENT_USER_PROFILE_SGID } from './api/queries/useCurrentSession';
import './App.css';
import CustomRoutes from './components/CustomRoute';
import Login from './components/Login';
import Home from './components/Home';
import { AppDataProvider } from './context/AppDataContext';
import ModalProvider from './context/ModalContext';
import theme from './theme/theme';

import PatientConfirmCost from './components/PatientConfirmCost';

const MUITheme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px',
          fontWeight: 400,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          fontWeight: 600,
        },
      },
    },
  },
});

interface QueryMap {
  [key: string]: any;
}

const apiQueryCtx = require.context('./api/queries', true, /.ts$/);
const queryMapping = apiQueryCtx
  .keys()
  .reduce((acc: QueryMap, path: string) => {
    const filename = path.split('./').pop();
    if (filename) {
      const key = filename.split('.ts');
      if (key.length) {
        return {
          ...acc,
          [key[0]]: apiQueryCtx(path).default,
        };
      }
    }
    return acc;
  }, {});

const onUnhandledError = () => <h3> There was an error. </h3>;

function AppContainer({
  hospital,
  loggedIn,
}: {
  hospital: string;
  loggedIn: boolean;
}) {
  return (
    <ModalProvider>
      <Flex minHeight="100vh">
        <Content hospital={hospital}>
          <CustomRoutes loggedIn={loggedIn} />
        </Content>
      </Flex>
    </ModalProvider>
  );
}

function AppRouter({
  onLogin,
  loggedIn,
}: {
  onLogin: (token: string, isMobileApp: boolean, isPatient?: boolean) => void;
  loggedIn: boolean;
}) {
  const { login, authState } = useAuth();
  const [cookies] = useCookies([
    Config.cookie.name,
    Config.cookie.patientUrlParamsSesh,
  ]);
  const sessionData = cookies && cookies[Config.cookie.name];
  let sessionTokenFromCookie = null;
  if (
    (sessionData && typeof sessionData === 'string') ||
    sessionData instanceof String
  ) {
    sessionTokenFromCookie = sessionData;
  } else if (
    typeof sessionData === 'object' &&
    !Array.isArray(sessionData) &&
    sessionData !== null
  ) {
    sessionTokenFromCookie = sessionData.token;
  }
  const sessionTokenFromURL = new URLSearchParams(window.location.search).get(
    'x-session'
  );
  const sessionTokenFromAuthState = authState && authState.token;
  const sessionToken =
    sessionTokenFromURL || sessionTokenFromAuthState || sessionTokenFromCookie;

  const sessParam = new URLSearchParams(window.location.search).get('session');
  const regimenParam = new URLSearchParams(window.location.search).get(
    'regimens'
  );

  const isPatientHasParamInfo = useCallback(
    () => !isEmpty(regimenParam),
    [regimenParam]
  );

  useEffect(() => {
    // authState might take a few seconds to get set
    if (sessionToken && !authState.authenticated) {
      login({ token: sessionToken });
      onLogin(sessionToken, Boolean(sessionTokenFromURL));
    }
  }, [sessionToken, sessParam, isPatientHasParamInfo]);

  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      {Config.ORGANIZATIONS.map((hospital: string) => (
        <Route key={hospital} path={`/${hospital.toLowerCase()}`}>
          <AppContainer hospital={hospital.toLowerCase()} loggedIn={loggedIn} />
        </Route>
      ))}

      <Route path={Config.REDIRECT_PATH_PATIENT_RETRIEVE_INFO}>
        {!authState.authenticated && isPatientHasParamInfo() && (
          <PatientRetrieveInfo />
        )}
      </Route>

      <Route path={Config.REDIRECT_PATH_PATIENT_DETAILS_CONFIRM}>
        {!isEmpty(cookies[`${Config.cookie.patientUrlParamsSesh}`]) ? (
          <PatientConfirmCost />
        ) : (
          <Home />
        )}
      </Route>

      {!authState.authenticated ? (
        <Route path="/">
          <Home />
        </Route>
      ) : null}
    </Switch>
  );
}

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const numOfHours = 3;
  const queryDefaultStaleTime = 1000 * 60 * 60 * numOfHours;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: false,
        refetchOnReconnect: false,
        staleTime: queryDefaultStaleTime,
      },
    },
  });

  const history = useHistory();
  const location = useLocation();

  /* eslint-disable-next-line */
  const [cookies, setCookie, removeCookie] = useCookies([
    Config.cookie.name,
    Config.cookie.patientCalcCostResultSesh,
    Config.cookie.patientDetailsSesh,
    Config.cookie.patientUrlParamsSesh,
  ]);
  const user = cookies && cookies[Config.cookie.name];

  const regimenParam = new URLSearchParams(window.location.search).get(
    'regimens'
  );

  const isPatientInParams = useCallback(
    () => !isEmpty(regimenParam),
    [regimenParam]
  );

  const applicationInput = JSON.stringify({
    domain: 'auth.botmd.io',
  });

  const themeLogo = JSON.stringify({
    logo: `https://botmd-production-hippocrates-static.s3.amazonaws.com/hospital/${user?.hospital?.toUpperCase()}/${user?.hospital?.toUpperCase()}_transparent.png`,
  });

  const redirectUrl = `${window.location.origin}${Config.REDIRECT_PATH}`;

  const onLoginCallback = (
    token: string,
    isMobileApp = false,
    isPatient = false
  ) => {
    const apiClient = new GraphQLClient(Config.HIPPO_GQL_ENDPOINT, {
      headers: {
        'X-SESSION': token,
      },
    });

    const gqlDocName = isPatient
      ? GRAPHQL_DOCUMENT_USER_PROFILE_SGID
      : GRAPHQL_DOCUMENT_USER_PROFILE_HOSPITAL;

    apiClient
      .request(gqlDocName, {})
      .then((resp) => {
        let cObj: {};
        let pathUrl: string;

        if (!isPatient && resp?.hospitalProfile) {
          const hospitalProfileObj = resp?.hospitalProfile;
          cObj = {
            token,
            firstName: hospitalProfileObj.firstName,
            lastName: hospitalProfileObj.lastName,
            fullName: hospitalProfileObj?.fullName,
            hospital: hospitalProfileObj.hospital.shortName,
            isMobileApp,
            directoryProfile: resp?.hospitalDirectoryProfiles?.allUids,
            hospitalDetail: hospitalProfileObj.hospital,
          };
          pathUrl = `/${hospitalProfileObj.hospital.shortName.toLowerCase()}`;
        } else {
          const userInfoObj = resp?.heimdallSgidUserInfo?.userInfo;
          cObj = {
            token,
            firstName: userInfoObj['myinfo.name'],
            lastName: '',
            hospital: 'NCIS',
            userInfo: userInfoObj,
            isMobileApp,
            directoryProfile: resp?.hospitalDirectoryProfiles?.allUids,
          };
          pathUrl = '/ncis';
        }

        // console.log('[debugger:on-login-callback] - resp: ', {
        //   cObj,
        //   resp,
        //   gqlDocName,
        // });

        removeCookie(Config.cookie.name, { path: '/' });
        if (token) {
          setCookie(Config.cookie.name, cObj, { path: '/' });
          setLoggedIn(true);
          history.push(pathUrl);
        }
      })
      .catch(() => {
        if (!isPatientInParams()) {
          removeCookie(Config.cookie.name, { path: '/' });
          removeCookie(Config.cookie.patientCalcCostResultSesh, { path: '/' });
          removeCookie(Config.cookie.patientDetailsSesh, { path: '/' });
          removeCookie(Config.cookie.patientUrlParamsSesh, { path: '/' });
          history.push('/');
          window.location.reload();
        } else {
          setLoggedIn(true);
          history.push(
            `${Config.REDIRECT_PATH_PATIENT_RETRIEVE_INFO}${location.search}`
          );
        }
      });
  };

  const onLogOutCallback = (hospital: string) => {
    removeCookie(Config.cookie.name, { path: `/${hospital}` });
    removeCookie(Config.cookie.name, { path: '/' });
    removeCookie(Config.cookie.patientCalcCostResultSesh, { path: '/' });
    removeCookie(Config.cookie.patientDetailsSesh, { path: '/' });
    removeCookie(Config.cookie.patientUrlParamsSesh, { path: '/' });
    return history.push(`/login?hospital=${hospital}`);
  };

  const loginUrl = `${Config.LOGIN_URL}?uid=${Config.LOGIN_PROVIDER_UID}&redirectTo=${redirectUrl}&applicationInput=${applicationInput}&theme=${themeLogo}`;

  return (
    <MUIThemeProvider theme={MUITheme}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/">
            <AuthProvider
              // NOTE: Do wee need this loginUrl for Doctors also?
              loginUrl={loginUrl}
              redirectPath={Config.REDIRECT_PATH}
              onLogin={onLoginCallback}
              onLogout={() => onLogOutCallback(user.hospital.toLowerCase())}
              sessionQueryKey="session"
            >
              <CookiesProvider>
                <AppDataProvider>
                  <ApiProvider
                    queryMapping={queryMapping}
                    endpoint={Config.GQL_ENDPOINT}
                  >
                    <QueryClientProvider client={queryClient}>
                      <ErrorBoundary fallbackRender={onUnhandledError}>
                        <AppRouter
                          onLogin={onLoginCallback}
                          loggedIn={loggedIn}
                        />
                      </ErrorBoundary>
                    </QueryClientProvider>
                  </ApiProvider>
                </AppDataProvider>
              </CookiesProvider>
            </AuthProvider>
          </Route>
        </Switch>
      </ThemeProvider>
    </MUIThemeProvider>
  );
}

export default App;
