import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const HOSPITAL_CHEMOCALC_DELIVER_REPORT_PDF_MUTATION = gql`
  mutation hospitalChemocalcDeliverReport(
    $input: HospitalChemocalcReportExportInput!
  ) {
    hospitalChemocalcReportExport(input: $input) {
      reportUrl
    }
  }
`;

export default createQuery({
  gqlDocument: HOSPITAL_CHEMOCALC_DELIVER_REPORT_PDF_MUTATION,
  queryType: 'mutation',
  baseQueryKey: 'hospitalChemocalcDeliverReportPDF',
  keepPreviousData: true,
});
