import {
  AccordionWidget,
  Badge,
  Body,
  Box,
  FAIcon,
  Flex,
  H5,
  H6,
  Select,
} from '@fivehealth/botero';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useAppData } from '../context/AppDataContext';
import RegimenBreakdown from './RegimenBreakdown';

function numberWithCommas(x: number) {
  return Math.round(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

type CycleOption = {
  value: number;
  label: string;
};

function DropDownIcon({ isOpen }: { isOpen: boolean }) {
  return (
    <Body ml={2}>
      <FAIcon
        icon={faChevronDown}
        style={{
          height: 16,
          transform: `rotate(${isOpen ? '180' : '0'}deg)`,
        }}
      />
    </Body>
  );
}

export default function RegimenSummary({
  isUserPatient,
}: {
  isUserPatient: boolean;
}) {
  const { results, selectedRegimens, setSelectedRegimens } = useAppData();
  return (
    <>
      {results.map((result, index) => {
        const currentRegimenIndex = selectedRegimens.findIndex(
          (selectedRegimen) =>
            selectedRegimen.regimen.regimen === result.userInputs.regimen
        );
        return (
          <AccordionWidget
            key={index}
            mt={2}
            data={[
              {
                id: 'test-3', // NOTE: Use as a unique key for the accordion widget
                name: 'testss',
                isOpen: !isUserPatient,
                data: {},
              },
            ]}
            width="100%"
            open
            renderHeader={(data: any) => (
              <Flex
                bg="white"
                justifyContent="space-between"
                flexDirection={['column', 'row']}
              >
                <Flex flexDirection="column" width={[192, 339]}>
                  <H5
                    display={['none', 'block']}
                    fontWeight={500}
                    color="#697481"
                  >
                    Regimen {currentRegimenIndex + 1} breakdown
                  </H5>
                  <H5 fontWeight={500}>{result.userInputs.regimen}</H5>
                  <H5 fontWeight={500} color="#697481">
                    Cycle length: {result.cost.cycleLength} days
                  </H5>
                </Flex>
                <Flex justifyContent="space-between">
                  <Box
                    onClick={(event: React.MouseEvent) =>
                      event.stopPropagation()
                    }
                    mr={1}
                  >
                    <Select
                      minWidth={172}
                      isDisabled={isUserPatient}
                      defaultValue={{
                        value: result.userInputs.numCycles,
                        label: `${result.userInputs.numCycles} cycle${
                          result.userInputs.numCycles === 1 ? '' : 's'
                        }/month`,
                      }}
                      options={[
                        { value: 1, label: '1 cycle/month' },
                        { value: 2, label: '2 cycles/month' },
                        { value: 3, label: '3 cycles/month' },
                        { value: 4, label: '4 cycles/month' },
                      ]}
                      styles={{
                        menu: (provided: any) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                        // option: (
                        //   styles: any,
                        //   { isFocused, isSelected }: any
                        // ) => ({
                        //   ...styles,
                        //   background: isFocused
                        //     ? 'hsla(291, 64%, 42%, 0.5)'
                        //     : isSelected
                        //     ? 'hsla(291, 64%, 42%, 1)'
                        //     : undefined,
                        //   zIndex: 1,
                        // }),
                      }}
                      onChange={(option: CycleOption) => {
                        setSelectedRegimens(
                          selectedRegimens.map(
                            (selectedRegimen, selectedIndex) =>
                              selectedIndex === currentRegimenIndex
                                ? { ...selectedRegimen, cycles: option.value }
                                : selectedRegimen
                          )
                        );
                      }}
                    />
                  </Box>
                  <Flex>
                    <Flex flexDirection="column" textAlign="end">
                      <H6 fontWeight={600} color="#697481">
                        List price
                      </H6>
                      <Badge>
                        <span>
                          {result.cost.breakdownBaseCost[0] ===
                          result.cost.breakdownBaseCost[1]
                            ? `$${numberWithCommas(
                                result.cost.breakdownBaseCost[0]
                              )}`
                            : `$${numberWithCommas(
                                result.cost.breakdownBaseCost[0]
                              )} - $${numberWithCommas(
                                result.cost.breakdownBaseCost[1]
                              )}`}
                        </span>
                      </Badge>
                    </Flex>
                    {!isUserPatient && <DropDownIcon isOpen={data.isOpen} />}
                  </Flex>
                </Flex>
              </Flex>
            )}
            renderBody={() => (
              <>
                <Box
                  margin="12px auto"
                  height={1}
                  width="100%"
                  backgroundColor="#D5D7DE"
                />

                {!isUserPatient && (
                  <Box>
                    <RegimenBreakdown
                      details={result.regimenFormularyKnapsack}
                      cycles={result.userInputs.numCycles}
                    />
                  </Box>
                )}
              </>
            )}
            containerProps={{
              padding: '16px',
              border: '1px solid #D5D7DE',
              borderRadius: '8px',
              marginTop: '16px',
              backgroundColor: 'white',
            }}
            headerProps={{
              width: '100%',
              backgroundColor: 'white',
              border: 0,
            }}
            bodyProps={{ backgroundColor: 'white', minHeight: 50 }}
            alternateBgColor={false}
          />
        );
      })}
    </>
  );
}
