const PCHI_OPTIONS = [
  {
    label: '$0 < PCHI ≤ $1200 [75% SDL & 75% MAF Subsidy]',
    value: 600,
  },
  {
    label: '$1200 < PCHI ≤ $2000 [75% SDL & 75% MAF Subsidy]',
    value: 1600,
  },
  {
    label: '$2000 < PCHI ≤ $3300 [50 % SDL & 50% MAF Subsidy]',
    value: 3000,
  },
  {
    label: '$3300 < PCHI ≤ $6500 [50 % SDL & 40 % MAF Subsidy]',
    value: 5000,
  },
  { label: 'PCHI > $6500 [50 % SDL Subsidy]', value: 10000 },
];

const DRUG_CHOICE_OPTIONS = [
  { label: 'Generic or Biosimilar', value: 'generic or biosimilar' },
  { label: 'Branded', value: 'branded' },
];

const SUBSIDY_OPTIONS = [
  { label: 'Subsidised', value: 'subsidised' },
  { label: 'Private', value: 'private' },
  { label: 'Non-Resident', value: 'non-resident' },
];

const CANCER_DIAGNOSIS_OPTIONS = [
  { label: 'Others', value: 'OTHERS' },
  { label: 'Bladder Cancer', value: 'BLADDER_CANCER' },
  { label: 'Breast Cancer', value: 'BREAST_CANCER' },
  { label: 'Colorectal Cancer', value: 'COLORECTAL_CANCER' },
  { label: 'Endometrial Cancer', value: 'ENDOMETRIAL_CANCER' },
  { label: 'Head And Neck Cancer', value: 'HEAD_AND_NECK_CANCER' },
  { label: 'Leukaemia', value: 'LEUKAEMIA' },
  { label: 'Liver Cancer', value: 'LIVER_CANCER' },
  { label: 'Lung Cancer ', value: 'LUNG_CANCER' },
  { label: 'Lymphoma', value: 'LYMPHOMA' },
  { label: 'Merkel Cell Cancer', value: 'MERKEL_CELL_CANCER' },
  {
    label: 'Multicentric Castlemans Disease',
    value: 'MULTICENTRIC_CASTLEMANS_DISEASE',
  },
  { label: 'Multiple Myeloma', value: 'MULTIPLE_MYELOMA' },
  {
    label: 'Myeloproliferative Neoplasms',
    value: 'MYELOPROLIFERATIVE_NEOPLASMS',
  },
  { label: 'Neuroendocrine', value: 'NEUROENDOCRINE' },
  { label: 'Ovarian Cancer', value: 'OVARIAN_CANCER' },
  { label: 'Pancreatic Cancer', value: 'PANCREATIC_CANCER' },
  { label: 'Prostate Cancer', value: 'PROSTATE_CANCER' },
  { label: 'Renal Cancer', value: 'RENAL_CANCER' },
  { label: 'Sarcoma', value: 'SARCOMA' },
  { label: 'Skin Cancer', value: 'SKIN_CANCER' },
  { label: 'Thoracic Cancer', value: 'THORACIC_CANCER' },
  { label: 'Thyroid Cancer', value: 'THYROID_CANCER' },
  { label: 'Tumour Agnostic', value: 'TUMOUR_AGNOSTIC' },
  {
    label: 'Upper Gastrointestinal Cancer',
    value: 'UPPER_GASTROINTESTINAL_CANCER',
  },
];

const RESIDENCY_OPTIONS = [
  { label: 'Singapore Citizen', value: 'sc' },
  { label: 'Permanent Resident', value: 'pr' },
];

const GENERATION_OPTIONS = [
  { label: 'NIL', value: '' },
  { label: 'Merdeka Generation', value: 'mg' },
  { label: 'Pioneer Generation', value: 'pg' },
];

export {
  PCHI_OPTIONS,
  DRUG_CHOICE_OPTIONS,
  SUBSIDY_OPTIONS,
  RESIDENCY_OPTIONS,
  GENERATION_OPTIONS,
  CANCER_DIAGNOSIS_OPTIONS,
};
