import {
  Body,
  Box,
  FAIcon,
  Flex,
  H4,
  H5,
  H6,
  PrimaryOutlinedButton,
  useApi,
  useAuth,
} from '@fivehealth/botero';
import { faChevronDown, faClose } from '@fortawesome/pro-regular-svg-icons';
import { sortBy, uniqBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { useCookies } from 'react-cookie';
import Config from '../Config';
import { useAppData } from '../context/AppDataContext';
import { useModal } from '../context/ModalContext';
import ErrorModal from './ErrorModal';
import { RegimenObject } from './Regimen.types';

type RegimenSearchData = {
  inputValue: string;
  suggestions: RegimenObject[];
  selected: RegimenObject | null;
};

export default function Regimen() {
  const [regimensSearchData, setRegimensSearchData] = useState<
    RegimenSearchData[]
  >([{ inputValue: '', suggestions: [], selected: null }]);

  const { authState } = useAuth();
  const [cookies] = useCookies([Config.cookie.name]);
  const user = cookies && cookies[Config.cookie.name];
  const { setRegimens, selectedRegimens, setSelectedRegimens, loading } =
    useAppData();
  const { openModal } = useModal();
  const {
    queries: { useRegimens },
  } = useApi({
    queries: ['useRegimens'],
  });
  const { data: { regimens: chernobylChemocalcRegimensData = [] } = {} } =
    useRegimens({
      enabled: authState.authenticated,
      staleTime: Infinity,
      onSuccess: (resp: any) => {
        setRegimens(resp?.data?.regimens as RegimenObject[]);
      },
      onError: () => openModal(<ErrorModal message="Server not responding" />),
      variables: {
        miniapp: `${user?.hospital?.toLowerCase()}_chemocalc`,
      },
    });

  const chernobylChemocalcRegimens = useMemo(
    () => sortBy(chernobylChemocalcRegimensData, [(r: any) => r.regimen]),
    [chernobylChemocalcRegimensData]
  );
  const getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase().replace(/ /g, '');
    const inputLength = inputValue.length;

    if (!chernobylChemocalcRegimens || inputLength < 2) {
      return [];
    }

    const filteredRegimens = chernobylChemocalcRegimens.filter(
      (regimen: RegimenObject) =>
        !selectedRegimens
          .map((selectedRegimen) => selectedRegimen.regimen)
          .includes(regimen) && regimen.regimen[0] !== '<' // NOTE: Regimen that starts with '<' are not to be included
    );

    const filterFuncs = [
      (regimen: RegimenObject) =>
        regimen.regimen
          .toLowerCase()
          .split(' ')
          .some((word) => word.replace(/\(|\)/g, '').startsWith(inputValue)),
      (regimen: RegimenObject) =>
        regimen.regimen.toLowerCase().replace(/ /g, '').includes(inputValue),
      (regimen: RegimenObject) =>
        regimen.synonyms.some((synonym) =>
          synonym.toLowerCase().replace(/ /g, '').startsWith(inputValue)
        ),
      (regimen: RegimenObject) =>
        regimen.synonyms.some((synonym) =>
          synonym.toLowerCase().replace(/ /g, '').includes(inputValue)
        ),
    ];

    const filteredRegimensByFunc = filterFuncs.reduce<RegimenObject[]>(
      (acc, func) =>
        acc.concat(
          filteredRegimens
            .filter(func)
            .sort((a: RegimenObject, b: RegimenObject) => {
              if (a.regimen < b.regimen) {
                return -1;
              }
              if (a.regimen > b.regimen) {
                return 1;
              }
              return 0;
            })
        ),
      []
    );

    return uniqBy(filteredRegimensByFunc, 'regimen');
  };

  const getSuggestionValue = (suggestion: RegimenObject) => suggestion.regimen;

  const renderSuggestion = (suggestion: RegimenObject) => (
    <Body px={2} py={1} fontSize={14}>
      {suggestion.regimen}
    </Body>
  );

  const handleOnChange = (index: number, newValue: string) => {
    if (regimensSearchData[index].selected) {
      setSelectedRegimens(
        selectedRegimens.filter(
          (selectedRegimen) =>
            selectedRegimen.regimen.regimen !==
            regimensSearchData[index].selected?.regimen
        )
      );
    }
    setRegimensSearchData((prevState) =>
      prevState.map((prevStateRegimenSearchData, prevStateIndex) =>
        prevStateIndex !== index
          ? prevStateRegimenSearchData
          : {
              ...prevStateRegimenSearchData,
              inputValue: newValue,
              selected: null,
            }
      )
    );
  };

  return (
    <Box width="380px" style={{ opacity: loading ? 0.4 : 1 }}>
      <H4>{`Patient's Treatment`}</H4>
      {regimensSearchData.map((regimenSearchData, index) => (
        <Box key={index}>
          <H6 mt={2} mb="4px" color="#697481">
            Regimen {index + 1}
          </H6>
          <Flex position="relative">
            <Autosuggest
              id={`autosuggest-${index}`}
              multiSection={false}
              theme={{
                container: {
                  flexGrow: 1,
                },
                input: {
                  width: 'calc(100% - 54px)',
                  padding: '12px 40px 12px 12px',
                  fontSize: '14px',
                  borderRadius: '8px',
                  borderStyle: 'solid',
                  borderColor: '#D5D7DE',
                  borderWidth: '1px',
                  textOverflow: 'ellipsis',
                  overflow: 'none',
                },
                inputFocused: {
                  borderColor: '#256BF6',
                  borderWidth: '1px',
                  outline: 'none',
                },
                suggestionsContainerOpen: {
                  marginTop: '4px',
                  width: '100%',
                  borderRadius: '8px',
                  borderStyle: 'solid',
                  borderColor: '#D5D7DE',
                  borderWidth: '1px',
                  padding: '8px 0',
                  boxShadow: '0px 6px 12px 0px rgba(152, 162, 179, 0.15)',
                },
                suggestionsList: {
                  listStyleType: 'none',
                  maxHeight: '300px',
                  overflowY: 'auto',
                  margin: 0,
                  padding: 0,
                },
                suggestionHighlighted: {
                  backgroundColor: '#F4F6F8',
                },
              }}
              suggestions={regimenSearchData.suggestions}
              onSuggestionsFetchRequested={({ value }: { value: string }) => {
                setRegimensSearchData((prevState) =>
                  prevState.map((prevStateRegimenSearchData, prevStateIndex) =>
                    prevStateIndex !== index
                      ? prevStateRegimenSearchData
                      : {
                          ...prevStateRegimenSearchData,
                          suggestions: getSuggestions(value),
                        }
                  )
                );
              }}
              onSuggestionsClearRequested={() => {
                setRegimensSearchData((prevState) =>
                  prevState.map((prevStateRegimenSearchData, prevStateIndex) =>
                    prevStateIndex !== index
                      ? prevStateRegimenSearchData
                      : {
                          ...prevStateRegimenSearchData,
                          suggestions: [],
                        }
                  )
                );
                if (
                  regimensSearchData[index].selected &&
                  regimensSearchData[index].selected?.regimen !==
                    regimensSearchData[index].inputValue
                ) {
                  setSelectedRegimens(
                    selectedRegimens.filter(
                      (selectedRegimen) =>
                        selectedRegimen.regimen.regimen ===
                        regimensSearchData[index].selected?.regimen
                    )
                  );
                }
              }}
              onSuggestionSelected={(event, { suggestion }) => {
                setRegimensSearchData((prevState) => {
                  const result = prevState.map(
                    (prevStateRegimenSearchData, prevStateIndex) =>
                      prevStateIndex !== index
                        ? prevStateRegimenSearchData
                        : {
                            ...prevStateRegimenSearchData,
                            selected: suggestion,
                          }
                  );
                  return result;
                });
                const clonedSelectedRegimens = selectedRegimens.slice(0);
                const wasPreviouslySelected =
                  regimensSearchData[index].selected !== null;
                clonedSelectedRegimens.splice(
                  index,
                  wasPreviouslySelected ? 1 : 0,
                  {
                    regimen: suggestion,
                    cycles: 1,
                  }
                );
                setSelectedRegimens(clonedSelectedRegimens);
              }}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={{
                disabled: loading,
                placeholder: 'Type to start searching',
                value: regimenSearchData.inputValue,
                onBlur: () => {
                  if (!regimensSearchData[index].selected) {
                    setRegimensSearchData((prevState) =>
                      prevState.map(
                        (prevStateRegimenSearchData, prevStateIndex) =>
                          prevStateIndex !== index
                            ? prevStateRegimenSearchData
                            : {
                                ...prevStateRegimenSearchData,
                                inputValue: '',
                              }
                      )
                    );
                  }
                },
                onChange: (
                  event: React.FormEvent<HTMLElement>,
                  { newValue }: { newValue: string }
                ) => handleOnChange(index, newValue),
              }}
            />
            <FAIcon
              style={{
                position: 'absolute',
                right: '8px',
                top: '3px',
                padding: '10px',
                width: '12px',
                cursor: regimensSearchData[index].inputValue.length
                  ? 'pointer'
                  : 'default',
              }}
              icon={
                regimensSearchData[index].inputValue.length
                  ? faClose
                  : faChevronDown
              }
              onClick={
                regimensSearchData[index].inputValue.length
                  ? () => handleOnChange(index, '')
                  : () => {}
              }
              fontSize="18px"
            />
            <FAIcon
              style={{
                width: '12px',
                position: 'absolute',
                right: '-28px',
                top: '12px',
                cursor: 'pointer',
                visibility: index !== 0 ? 'visible' : 'hidden',
              }}
              icon={faClose}
              fontSize="18px"
              onClick={
                loading
                  ? () => {}
                  : () => {
                      setRegimensSearchData((prevState) =>
                        prevState.filter(
                          (prevStateRegimenSearchData, prevStateIndex) =>
                            prevStateIndex !== index
                        )
                      );
                      if (regimensSearchData[index].selected) {
                        setSelectedRegimens(
                          selectedRegimens.filter(
                            (selectedRegimen) =>
                              selectedRegimen.regimen.regimen !==
                              regimensSearchData[index].selected?.regimen
                          )
                        );
                      }
                    }
              }
            />
          </Flex>
        </Box>
      ))}
      {regimensSearchData.length <= 3 && (
        <PrimaryOutlinedButton
          style={{
            border: 'none',
          }}
          bgHovered="emptyShade"
          mt={2}
          disabled={regimensSearchData.length === 3}
          onClick={
            loading
              ? () => {}
              : () =>
                  setRegimensSearchData((prevState) => [
                    ...prevState,
                    { inputValue: '', suggestions: [], selected: null },
                  ])
          }
        >
          <H5 color="primary">
            ＋ Add regimen {regimensSearchData.length === 3 ? '(max 3)' : ''}
          </H5>
        </PrimaryOutlinedButton>
      )}
    </Box>
  );
}
