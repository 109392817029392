import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

// DEPRECATED
export const HOSPITAL_CHEMOCALC_DELIVER_MSG_MUTATION = gql`
  mutation hospitalChemocalcDeliverMessage(
    $input: HospitalChemocalcMessageTemplateDeliverInput!
  ) {
    hospitalChemocalcMessageTemplateDeliver(input: $input) {
      results {
        isDelivered
        email
        messageTemplateUid
      }
    }
  }
`;

export default createQuery({
  gqlDocument: HOSPITAL_CHEMOCALC_DELIVER_MSG_MUTATION,
  queryType: 'mutation',
  baseQueryKey: 'hospitalChemocalcDeliverMessage',
  keepPreviousData: true,
});
