import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, H4, Input, PrimaryButton, Body } from '@fivehealth/botero';
import { GraphQLClient } from 'graphql-request';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { HEIMDALL_AUTHORIZATION_FLOW } from '../api/queries/useHeimdallAuthFlow';
import BotmdLogo from '../assets/botmd_logo.svg';
import PatientSGIDImage from '../assets/med_report_sgid_patient_home.png';
import SGIDbuttonImg from '../assets/sgid/Primary.png';
import Config from '../Config';
import {
  decodeURLString,
  formalizeString,
  PatientURLParamsType,
  SubsidyStatus,
} from '../utils/global';

function PatientRetrieveInfo() {
  const history = useHistory();
  /* eslint-disable-next-line */
  const [_, setCookie, removeCookie] = useCookies([
    Config.cookie.name,
    Config.cookie.patientCalcCostResultSesh,
    Config.cookie.patientDetailsSesh,
    Config.cookie.patientUrlParamsSesh,
  ]);

  const [nric, setNric] = useState('');
  const [sessObjState, setSessObjState] = useState<
    Partial<PatientURLParamsType>
  >({} as PatientURLParamsType);

  const sessParam = new URLSearchParams(window.location.search).get('session');
  const regimenParam = decodeURLString(
    new URLSearchParams(window.location.search).get('regimens') || ''
  );
  const weightParam = new URLSearchParams(window.location.search).get('weight');
  const heightParam = new URLSearchParams(window.location.search).get('height');
  const doctorParam = new URLSearchParams(window.location.search).get('doctor');
  const hospitalParam = new URLSearchParams(window.location.search).get(
    'hospital'
  );
  const diagnosisParam = new URLSearchParams(window.location.search).get(
    'diagnosis'
  );
  const subsidyStatusParam = new URLSearchParams(window.location.search).get(
    'subsidyStatus'
  );
  const nricParam = new URLSearchParams(window.location.search).get('nric');
  const drugIndParam = new URLSearchParams(window.location.search).get(
    'drugsInd'
  );

  // console.log('[debugger:retrieve-info] - drugIndParam: ', drugIndParam);

  const isPatientHasData = useCallback(
    () =>
      !isEmpty(regimenParam) &&
      !isEmpty(doctorParam) &&
      !isEmpty(subsidyStatusParam) &&
      !isEmpty(hospitalParam),
    [regimenParam, subsidyStatusParam]
  );

  const handleSingpassRedirect = (sessObjParam?: any, nricVal?: string) => {
    const apiClient = new GraphQLClient(Config.HIPPO_GQL_ENDPOINT);
    let providerInput: any = {
      sgid_authorize: true,
    };

    if (nricVal) {
      providerInput = {
        chemocalc_nric: nricVal,
      };
    }

    const req = {
      input: {
        providerApplicationUid: nricVal
          ? Config.LOGIN_PROVIDER_UID_SGID_CHEMO_NRIC
          : Config.LOGIN_PROVIDER_UID_SGID,
        providerInput,
        redirectTo: `${window.location.origin}${Config.REDIRECT_PATH_PATIENT_DETAILS_CONFIRM}`,
        applicationInput: {
          organization_key: 'ncis', // NOTE: This is a hardcoded key only
          allow_guest: false,
        },
      },
    };

    apiClient
      .request(HEIMDALL_AUTHORIZATION_FLOW, req)
      .then((resp) => {
        if (sessObjParam) {
          const obj = {
            ...sessObjParam,
            nric: nricVal ?? '',
          };

          // console.log(
          //   '[debugger:retrieve-info] - handleSingpassRedirect response : ',
          //   JSON.stringify(resp, null, 1)
          // );
          // console.log(
          //   '[debugger:retrieve-info] - handleSingpassRedirect response session obj: ',
          //   JSON.stringify(obj, null, 1)
          // );

          setSessObjState(obj);
          setCookie(`${Config.cookie.patientUrlParamsSesh}`, obj, {
            path: '/',
          });
        }

        // NOTE: Use forward link instead of redirecting
        window.location.href = resp?.heimdallAuthorizationFlow?.redirectTo;
      })
      .catch(() => history.push('/error'));
  };

  const handleLogin = () => {
    handleSingpassRedirect(sessObjState, nric);
  };

  const clearAllCookies = (hospital: string) => {
    removeCookie(Config.cookie.name, { path: `/${hospital}` });
    removeCookie(Config.cookie.name, { path: '/' });
    removeCookie(Config.cookie.patientCalcCostResultSesh, { path: '/' });
    removeCookie(Config.cookie.patientDetailsSesh, { path: '/' });
    removeCookie(Config.cookie.patientUrlParamsSesh, { path: '/' });
  };

  useEffect(() => {
    if (isEmpty(sessObjState)) {
      const obj: PatientURLParamsType = {
        token: sessParam,
        regimens: JSON.parse(decodeURLString(regimenParam || '')),
        weight: weightParam || null,
        height: heightParam || null,
        doctor: doctorParam,
        hospital: hospitalParam,
        diagnosis: diagnosisParam,
        nric: nricParam || '',
        subsidyStatus: subsidyStatusParam as SubsidyStatus,
        drugsInd: drugIndParam ? JSON.parse(decodeURLString(drugIndParam)) : [],
      };

      setNric(nricParam || '');
      setSessObjState(obj as PatientURLParamsType);
      clearAllCookies(hospitalParam || '');
    }
  }, []);

  const renderDoctorDisplay = () => (
    <Body style={{ fontSize: 16, fontWeight: 400 }}>
      <Body as="span" style={{ marginRight: 4 }}>
        Dr
      </Body>
      <Body as="span" style={{ fontWeight: 600, marginRight: 4 }}>
        {sessObjState.doctor?.split('|')[0] || ''}
      </Body>
      <Body as="span" style={{ marginRight: 4 }}>
        from
      </Body>
      <Body as="span" style={{ fontWeight: 600, marginRight: 4 }}>
        {formalizeString(hospitalParam || '', true)?.toUpperCase()}
      </Body>
      <Body
        as="span"
        style={{ marginRight: 4 }}
      >{` has sent you your ChemoCost calculator link.`}</Body>
    </Body>
  );
  return (
    <>
      <Box id="nav" display={['initial']}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          height={64}
          style={{ borderBottom: '1px solid #d8d7d7' }}
        >
          <Box ml={2} as="img" src={BotmdLogo} width={100} />

          <H4 ml={-12}>ChemoCost What</H4>
          <Flex />
        </Flex>
      </Box>

      <Flex
        justifyContent="center"
        backgroundColor="rgb(244, 246, 248)"
        height="100vh"
      >
        <Box
          maxWidth={[340, 600]}
          height="fit-content"
          p={4}
          mt={4}
          mx={2}
          borderRadius={10}
          style={{ backgroundColor: 'white', border: '1px solid #d2d2d2' }}
        >
          <Flex justifyContent="center">
            <Box as="img" width={250} src={PatientSGIDImage} />
          </Flex>
          <Flex fontWeight={600} fontSize={16} justifyContent="center" mt={4}>
            Welcome to ChemoCost What
          </Flex>
          <Flex
            my={4}
            fontWeight={400}
            fontSize={15}
            justifyContent="center"
            textAlign="center"
          >
            {renderDoctorDisplay()}
          </Flex>
          <Box my={4} textAlign="center" fontSize={16}>
            For an accurate quote of out-of-pocket costs, please log into your
            Singpass so your subsidies and Medishield balance can be taken into
            account.
          </Box>
          <Box mt={6}>
            <Flex m={2} justifyContent="center">
              <Box
                as="img"
                width={267}
                src={SGIDbuttonImg}
                onClick={() => {
                  handleSingpassRedirect(sessObjState);
                }}
                style={{
                  cursor:
                    !isPatientHasData() || nricParam
                      ? 'not-allowed'
                      : 'pointer',
                }}
              />
            </Flex>

            <Flex mt={2} justifyContent="center">
              <Box
                width={550}
                height={20}
                style={{ borderBottom: '1px solid #d2d2d2' }}
              />
            </Flex>
            <Flex mx={2} justifyContent="center">
              <Box
                color="#d2d2d2"
                style={{
                  backgroundColor: 'white',
                  position: 'relative',
                  top: '-12px',
                }}
                px={2}
              >
                OR
              </Box>
            </Flex>

            <Flex mt={1} justifyContent="center">
              <Box>
                <Input
                  borderRadius={4}
                  labelProps={{ style: { display: 'none' } }}
                  ml={1}
                  // height="48px"
                  maxWidth="235px"
                  value={nric}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter' && isPatientHasData()) {
                      handleLogin();
                    }
                  }}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setNric(e.currentTarget.value)
                  }
                  placeholder="Enter NRIC"
                  style={{ borderRadius: 4 }}
                />
              </Box>

              <Box style={{ position: 'relative', top: 8 }} ml={1}>
                <PrimaryButton
                  onClick={handleLogin}
                  style={{ borderRadius: 8, height: 45 }}
                  disabled={!isPatientHasData() || nric === ''}
                >
                  Login
                </PrimaryButton>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </>
  );
}

export default PatientRetrieveInfo;
