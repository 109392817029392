import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const HOSPITAL_CHEMOCALC_DELIVER_REPORT_EMAIL_MUTATION = gql`
  mutation hospitalChemocalcDeliverReport(
    $input: HospitalChemocalcReportDeliverInput!
  ) {
    hospitalChemocalcReportDeliver(input: $input) {
      results {
        isDelivered
        email
        messageTemplateUid
      }
    }
  }
`;

export default createQuery({
  gqlDocument: HOSPITAL_CHEMOCALC_DELIVER_REPORT_EMAIL_MUTATION,
  queryType: 'mutation',
  baseQueryKey: 'hospitalChemocalcDeliverReport',
  keepPreviousData: true,
});
