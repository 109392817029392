import { Box } from '@fivehealth/botero';
import React from 'react';
import Header from './Header';
import { isUserPatient } from '../utils/global';
import { useAppData } from '../context/AppDataContext';

export interface ContentProps {
  children?: React.ReactNode;
  hospital: string;
}

function Content(props: ContentProps) {
  const { session } = useAppData();

  return (
    <Box flex={1} overflowY="hidden" overflowX="hidden" flexDirection="column">
      <Box
        id="appHeader"
        pl={[2, 8]}
        pr={[2, 8]}
        flex={1}
        overflowY="hidden"
        overflowX="hidden"
        borderBottom="1px solid #D5D7DE"
      >
        <Box maxWidth={1920} m="auto">
          <Header hospital={props.hospital} />
        </Box>
      </Box>
      <Box
        id="appContent"
        px={3}
        mb="100px"
        minHeight="100%"
        overflowY="hidden"
        overflowX="hidden"
        backgroundColor={isUserPatient(session) ? 'lightestShade' : 'white'}
      >
        <Box maxWidth={1100} m="auto">
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}
export default Content;
