import { capitalize, isEmpty, isNumber, trim } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const isUserPatient = (session: any) => {
  if (isEmpty(session)) return false;
  const res = (session as any)?.scopes[0]?.toString().toLowerCase();
  // return true;
  return res === 'sgid' || res === 'hospital:chemocalc';
};

export function numberWithCommas(x: number | null | undefined) {
  const withCommas = (Math.sign(x as number) * Math.round(Math.abs(Number(x))))
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (withCommas.startsWith('-')) {
    return `-$${withCommas.substring(1)}`;
  }
  return `$${withCommas}`;
}

export const getSubsidyStatus = (
  result: any,
  mediShieldLife: any,
  session?: string
): SubsidyType => {
  const resObj: SubsidyType = {} as SubsidyType;

  if (result?.userInputs?.subsidyStatus === 'subsidised') {
    resObj.subsidyStatus = 'Subsidised';
  } else if (result?.userInputs?.subsidyStatus === 'private') {
    resObj.subsidyStatus = 'Private';
  } else {
    resObj.subsidyStatus = 'Non-Resident';
  }

  if (
    result?.userInputs?.subsidyStatus !== 'non-resident' &&
    result?.userInputs?.residency === 'sc'
  ) {
    resObj.residency = 'Singapore Citizen';
  } else if (
    result?.userInputs?.subsidyStatus !== 'non-resident' &&
    result?.userInputs?.residency === 'pr'
  ) {
    resObj.residency = 'Permanent Resident';
  }

  if (
    result?.userInputs?.residency === 'sc' &&
    result?.userInputs?.generation === 'mg'
  ) {
    resObj.generation = 'Merdeka Generation';
  } else if (
    result?.userInputs?.residency === 'sc' &&
    result?.userInputs?.generation === 'pg'
  ) {
    resObj.generation = 'Pioneer Generation';
  } else if (result?.userInputs?.residency === 'sc') {
    // resObj.residency = 'NIL';
    resObj.residency = 'Singaporean';
  }

  if (
    result?.userInputs?.subsidyStatus === 'subsidised' &&
    result?.userInputs?.phci <= 1200
  ) {
    resObj.phci = isUserPatient(session)
      ? 'Eligible — 75% subsidy & 75% MAF Subsidy'
      : '$0 < PCHI ≤ $1200 [75% SDL & 75% MAF Subsidy]';
  } else if (
    result?.userInputs?.subsidyStatus === 'subsidised' &&
    result?.userInputs?.phci <= 2000
  ) {
    resObj.phci = isUserPatient(session)
      ? 'Eligible — 75% SDL & 75% MAF Subsidy'
      : '$1200 < PCHI ≤ $2000 [75% SDL & 75% MAF Subsidy]';
  } else if (
    result?.userInputs?.subsidyStatus === 'subsidised' &&
    result?.userInputs?.phci <= 3300
  ) {
    resObj.phci = isUserPatient(session)
      ? 'Eligible — 50 % SDL & 50% MAF Subsidy'
      : '$2000 < PCHI ≤ $3300 [50 % SDL & 50% MAF Subsidy]';
  } else if (
    result?.userInputs?.subsidyStatus === 'subsidised' &&
    result?.userInputs?.phci <= 6500
  ) {
    resObj.phci = isUserPatient(session)
      ? 'Eligible — 50% SDL & 40 % MAF Subsidy'
      : '$3300 < PCHI ≤ $6500 [50% SDL & 40% MAF Subsidy]';
  } else if (result?.userInputs?.subsidyStatus === 'subsidised') {
    resObj.phci = isUserPatient(session)
      ? 'Eligible — 50 % SDL Subsidy'
      : 'PCHI > $6500 [50 % SDL Subsidy]';
  }

  resObj.medishield =
    (mediShieldLife as any).toString().toLowerCase() !== 'infinity'
      ? 'Yes'
      : 'No';
  resObj.medishieldBalance = `${numberWithCommas(
    (mediShieldLife as any).toString().toLowerCase() === 'infinity'
      ? 0
      : mediShieldLife
  )}`;

  return resObj;
};

export const getCitizenship = (userInfo: any) => {
  if (isEmpty(userInfo) || !userInfo['myinfo.residentialstatus']) return '';
  if (
    userInfo['myinfo.nationality']?.toString()?.toUpperCase() === 'SINGAPOREAN'
  ) {
    return 'Singaporean';
  }

  if (
    userInfo['myinfo.residentialstatus']?.toString()?.toUpperCase() === 'PR'
  ) {
    return 'Singapore PR';
  }

  if (
    userInfo['myinfo.residentialstatus']?.toString()?.toUpperCase() === 'SC'
  ) {
    return 'Singaporean';
  }

  return 'Entry Permit';
};

export const getCitizenshipPatientInfo = (userInfo: any) => {
  if (userInfo['myinfo.residentialstatus'].toString()?.toUpperCase() === 'SC') {
    return 'Singaporean';
  }

  return 'N/A';
};

export const getDobDateFormatted = (dateStr: string) => {
  const dobDateObj = new Date(dateStr);

  const month = isNumber(dobDateObj.getUTCMonth())
    ? dobDateObj.toLocaleString('en-us', { month: 'short' })
    : 'MMM';

  return `${dobDateObj.getDate() || 'dd'} ${month} ${
    dobDateObj.getFullYear() || 'yyyy'
  }`;
};

export type SubsidyType = {
  subsidyStatus: string;
  residency: string;
  generation: string;
  phci: string;
  medishield?: string;
  medishieldBalance?: string;
};

export type UserInfoType = Pick<
  PatientURLParamsType,
  'weight' | 'height' | 'diagnosis'
> & {
  directoryProfile: string[];
  token: string;
  firstName: string;
  lastName: string;
  hospital: string;
  fullName: string;
  hospitalDetail: {
    shortName: string;
    name: string;
    organization: string;
  };
  isMobileApp: string;
  subsidyStatus?: SubsidyStatus;
};

export type PersonalDetailsType = {
  token: string;
  name: string;
  nric: string;
  email: string;
  nationality: string;
  residentialstatus: string;
  dob: string | Date;
  generation?: string;
  means?: string;
  diagnosis?: string;
  weight?: number;
  height?: number;
};

export type InsuranceDetailsType = {
  provider: string;
  plan: InsuranceSubsidyRespType['ip'];
  monthlyLimit: string | number;
  monthlyClaimed: number;
  yearlyLimit: string | number;
  yearlyClaimed: number;
  yearlyBalance: number;
  rider: InsuranceSubsidyRespType['rider'];
  riderMonthlyLimit: number;
  riderYearlyLimit: number;
  riderYearlyClaimed: number;
};

export type MedishieldDetailsType = {
  limit: number;
  yearlyLimit: number;
  yearlyBalance: number;
  claimed: number;
  yearlyClaimed: number;
  class?: string;
};

export type MedisaveDetailsType = {
  balance: number;
  claimed: number;
  yearlyLimit: number;
  yearlyClaimed: number;
  yearlyBalance: number;
  limit: number;
};

export type PatientInfoDetailsType = {
  personalDetails: PersonalDetailsType;
  insuranceDetails?: InsuranceDetailsType;
  medishieldDetails?: MedishieldDetailsType;
  medisaveDetails: MedisaveDetailsType;
  finalCost: FinalCostType;
  // chemocalcResults?: ChemocalcResult[];
};

export type PatientURLParamsType = {
  session?: string | null;
  token?: string | null;
  regimens: HospitalChemocalcInputRegimensType[];
  weight?: string | number | null;
  height?: string | number | null;
  doctor?: string | null;
  hospital: string | null;
  nric?: string | null;
  diagnosis?: string | null;
  subsidyStatus?: SubsidyStatus;
  drugsInd?: DrugIndication[] | null;
};

export interface PatientVariablesType {
  patientDetails?: Partial<Omit<PatientURLParamsType, 'session'>>;
  additionalDetails?: PatientVariablesAdditionalDetailsType;
  filteredSubsidies?: PatientVariablesFilteredSubsidiesType;
}

export interface PatientVariablesAdditionalDetailsType {
  brandStatus: string;
  mafDrugs: any[];
}
export interface PatientVariablesFilteredSubsidiesType {
  subsidy: 'subsidised' | 'private' | 'non-resident' | string;
  residency: string;
  generation: string;
  phci: number;
}

export enum MtsocSchemaCodeEnum {
  Maf = 'maf',
  Soc = 'soc',
}

export enum Citizenship {
  EntryPermit = 'ENTRY_PERMIT',
  ScParent = 'SC_PARENT',
  Singaporean = 'SINGAPOREAN',
  SingaporePr = 'SINGAPORE_PR',
  SprParent = 'SPR_PARENT',
}
export type HospitalChemocalcInputRegimensType = {
  regimen: string;
  numCycles: number;
};

export type HospitalChemocalcInputRequestType = {
  userId?: string;
  nric?: string;
  subsidyStatus: SubsidyStatus;
  healthcareFacility: MtsocFacilityCode;
  dateOfBirth?: string;
  cancerType: CancerType;
  regimens: HospitalChemocalcInputRegimensType[];
  schemaCodes: Array<MtsocSchemaCodeEnum>;
  height: number | null;
  weight: number | null;
  drugIndications?: Array<any>;
  accountNumber?: any;
};

export interface FinalCostType {
  base_cost: number;
  maf_subsidy: number;
  sdl_subsidy: number;
  mshl_ip_subsidy: number;
  msv_subsidy: number;
  reasons: string[];
  base_cost_cds: number;
  total_oop: number;
}

export type HospitalChemocalcOutputResponseType = {
  hospitalChemocalc: {
    subsidy: SubsidyRespType;
    insuranceSubsidy: InsuranceSubsidyRespType;
    chemocalcResults: ChemocalcResult[];
    finalCost: FinalCostType;
  };
};
export interface ChemocalcResult {
  userInputs: UserInputs;
  cost: ChemocalcResultCostType;
  regimenFormularyKnapsack: RegimenFormularyKnapsack[];
  mafIndications: any[];
  disclaimer: string;
}

interface RegimenFormularyKnapsack {
  drug: Drug;
  formularyItems: FormularyItem[];
}
interface FormularyItem {
  formularyItem: string;
  sdlPrice: number;
  mafPrice: number;
  basePrice: number;
  quantityPerDose: number;
  dosePerCycle: number;
  alternates: string;
  doseRate: string;
}
interface Drug {
  drug: string;
  dosePerUnit: number;
  doseUnit: string;
  dosePerCycle: number;
  doseMethod: string;
  totalDose: number;
  doseRate: string;
}
interface ChemocalcResultCostType {
  msWithdrawPerMonth: number;
  mslClaimPerMonth: number;
  mslCoPayPerMonth: number[];
  papSubPerMonth: number;
  breakdownBaseCost: number[];
  cycleLength: number;
  baseCost: number[];
  sdlSubsidy: number[];
  mafSubsidy: number[];
  servicesCost: number[];
}
interface UserInputs {
  regimen: string;
  height?: any;
  weight?: any;
  bsa?: any;
  phci: number;
  residency: string;
  generation: string;
  brandStatus: string;
  subsidyStatus: string;
  mafDrugs: any[];
  numCycles: number;
  drugIndications: DrugIndication[];
}
interface DrugIndication {
  drug: string;
  maf: boolean | null;
  cdl: boolean | null;
  indications?: string;
}
interface InsuranceSubsidyRespType {
  name: string;
  nric: string;
  residential: string;
  dob: string;
  insurance_provider: string;
  ip: {
    ip_cdt_monthly_claimed: number;
    ip_cds_yearly_claimed: number;
    ip_yearly_balance: number;
    ip_cdt_monthly_limit: number;
    ip_cds_yearly_limit: number;

    ip_plan: string;
    // ip_cds_monthly_limit: number;
    // ip_claimed: number;
  };
  rider: {
    // rider_claimed: number;
    // rider_cdt_yearly_limit: number;
    // rider_cds_monthly_limit: number;

    rider_plan: string;
    rider_cds_yearly_claimed: number;
    rider_cdt_monthly_claimed: number;
    rider_cdt_montly_limit: number;
    rider_cds_yearly_limit: number;
  };
  msv: {
    // msv_monthly_claimed: number;
    // msv_monthly_limit: number;
    // msv_yearly_claimed: number;
    // msv_yearly_limit: number;

    msv_balance: number;
    msv_cds_yearly_claimed: number;
    msv_cds_yearly_limit: number;
    msv_cdt_monthly_limit: number;
    msv_cdt_monthly_claimed: number;
  };
  mshl: {
    mshl_class: string;
    // mshl_monthly_claimed: number;
    // mshl_monthly_limit: number;
    // mshl_yearly_claimed: number;
    // mshl_yearly_limit: number;

    mshl_cdt_monthly_claimed: number;
    mshl_cds_yearly_claimed: number;
    mshl_cds_yearly_limit: number;
    mshl_yearly_balance: number;
    mshl_cdt_monthly_limit: number;
  };
  cdl_eligibility: boolean;
}
export interface SubsidyRespType {
  sub: SubRespType[];
  pg: string;
  og: string;
  status: StatusRespType;
}
interface StatusRespType {
  uid: string;
  code: string;
  desc: string;
}
interface SubRespType {
  sch: string;
  band: string;
  pct: string;
  b2: string;
  b2p: string;
  sd: string;
  oc: string;
}

export enum CancerType {
  BladderCancer = 'BLADDER_CANCER',
  BreastCancer = 'BREAST_CANCER',
  ColorectalCancer = 'COLORECTAL_CANCER',
  EndometrialCancer = 'ENDOMETRIAL_CANCER',
  HeadAndNeckCancer = 'HEAD_AND_NECK_CANCER',
  Leukaemia = 'LEUKAEMIA',
  LiverCancer = 'LIVER_CANCER',
  LungCancer = 'LUNG_CANCER',
  Lymphoma = 'LYMPHOMA',
  MerkelCellCancer = 'MERKEL_CELL_CANCER',
  MulticentricCastlemansDisease = 'MULTICENTRIC_CASTLEMANS_DISEASE',
  MultipleMyeloma = 'MULTIPLE_MYELOMA',
  MyeloproliferativeNeoplasms = 'MYELOPROLIFERATIVE_NEOPLASMS',
  Neuroendocrine = 'NEUROENDOCRINE',
  Others = 'OTHERS',
  OvarianCancer = 'OVARIAN_CANCER',
  PancreaticCancer = 'PANCREATIC_CANCER',
  ProstateCancer = 'PROSTATE_CANCER',
  RenalCancer = 'RENAL_CANCER',
  Sarcoma = 'SARCOMA',
  SkinCancer = 'SKIN_CANCER',
  ThoracicCancer = 'THORACIC_CANCER',
  ThyroidCancer = 'THYROID_CANCER',
  TumourAgnostic = 'TUMOUR_AGNOSTIC',
  UpperGastrointestinalCancer = 'UPPER_GASTROINTESTINAL_CANCER',
}
export enum MtsocFacilityCode {
  NuhsPolyclinic = 'NUHS_POLYCLINIC',
  SinghealthPolyclinic = 'SINGHEALTH_POLYCLINIC',
  Test = 'TEST',
}

export enum SubsidyStatus {
  NonResident = 'NON_RESIDENT',
  Private = 'PRIVATE',
  Subsidies = 'SUBSIDIES',
}

export type HospitalChemocalcMessageTemplateDeliverInput = {
  email?: string;
  messageTemplateUid: string;
  phone?: string;
  userVariables: Array<{ name: string; value: string }>;
};

export type HospitalChemocalcMessageTemplateDeliveryResult = {
  email?: string;
  isDelivered: boolean;
  messageTemplateUid: string;
  phone?: string;
};

export const encodeURLString = (param: {} | [] | unknown) => {
  if (!param) return '';
  return encodeURIComponent(JSON.stringify(param));
};

export const decodeURLString = (param: string) => {
  if (!param) return '';
  const o = decodeURIComponent(param);
  return JSON.parse(JSON.stringify(o));
};

export const convertToPositiveInt = (
  num: number | string | undefined
): number => (Number(num) < 0 ? Math.abs(num as number) : Number(num));

export const formalizeString = (
  text: string | undefined | null,
  isOnlyAlphaNumeric?: boolean,
  isCaseSentive?: boolean
): string => {
  let res = capitalize(trim(text || '-')) as string;

  // || text?.toLowerCase() !== 'n a'
  if (text?.toLowerCase() !== 'n/a') {
    res = trim(res.replace(/[\W_]+/g, ' '));
  }

  if (isOnlyAlphaNumeric) {
    res = trim(res.replace(/[\W_]+/g, ' '));
  }

  if (isCaseSentive)
    res = res.replace(/(^\w{1})|(\s+\w{1})/g, (l) => l.toUpperCase());

  return res.toString();
};

export const maskStringValue = (
  str: string | null | undefined,
  start: number,
  end: number,
  charReplace = 'X'
) => {
  if (
    !str ||
    start < 0 ||
    start >= str.length ||
    end < 0 ||
    end > str.length ||
    start >= end
  ) {
    return str;
  }
  const maskLength = end - start;
  return (
    // eslint-disable-next-line no-unsafe-optional-chaining
    str?.substring(0, start) +
    charReplace.repeat(maskLength) +
    str.substring(end)
  );
};

export function validateEmail(email: string) {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

// HospitalChemocalcReportExportInput
export type HospitalChemocalcShareExportInputType = {
  cancerType: CancerType;
  clientMutationId: String;
  doctorUid: String;
  drugIndications: any;
  email?: String;
  healthcareFacility: MtsocFacilityCode;
  height: number;
  weight: number;
  regimens: Record<string, any>[];
  schemaCodes: MtsocSchemaCodeEnum[];
  subsidyStatus: SubsidyStatus[];
};

export const getGraphyPercentageVal = (
  claimed: number | undefined | string,
  limit: number | undefined | string
): number => {
  const noClaimLimitCheck = Number(claimed) <= 0 && Number(limit) <= 0;
  const isNotValidCheck = Number(claimed) > Number(limit);
  if (noClaimLimitCheck || isNotValidCheck) return 100;

  const res =
    (Math.ceil(Math.round(Number(limit)) - Math.round(Number(claimed))) /
      Number(limit)) *
    100;

  if (res === Infinity) {
    return 100;
  }

  const diff = 100 - Math.ceil(res);

  if (diff > 100 || diff < 0) {
    return 100;
  }
  if (diff === 0) {
    return -1;
  }
  return diff;
};

export const getGraphBalanced = (
  limit: number,
  claimed: number,
  type?: 'ip' | 'msv' | 'msh' | 'def'
) => {
  const diff = convertToPositiveInt(limit) - convertToPositiveInt(claimed);
  let bType;
  switch (type) {
    case 'ip':
      bType = 'Integrated Shield Plan & Rider';
      break;
    case 'msv':
      bType = 'Medisave';
      break;
    case 'msh':
      bType = 'Medishield';
      break;
    default:
      bType = null;
  }
  if (bType === null) {
    const dNum = convertToPositiveInt(limit) - convertToPositiveInt(claimed);
    return dNum < 0 ? 0 : dNum;
  }
  if (diff < 0) {
    return `No more ${bType} Balance for rest of the claim`;
  }
  return `Balance: ${numberWithCommas(
    convertToPositiveInt(limit) - convertToPositiveInt(claimed)
  )}`;
};

export const emailSupport = (subject = 'Chemocalc - Support') => {
  const url = new URL(`mailto:support@botmd.io`);
  url.searchParams.set('subject', encodeURIComponent(subject));
  window.open(decodeURI(url.toString()));
};
