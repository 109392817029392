import { Box, FAIcon, Flex, H5, Text, Toggle } from '@fivehealth/botero';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useExpanded, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import styled from 'styled-components';
import { FormularyItem, KnapsackItem } from './Results.types';

const Styles = styled.div`
  .table {
    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        //z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
      }

      .footer {
        bottom: 0;
      }

      .body {
        position: relative;
        //z-index: 11;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 1px 0px 0px 1px rgb(213, 215, 222);
        background: white;
      }
      .sub-cell {
        background-color: #f4f6f8;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #d5d7de;
      }
    }
  }
`;

function numberWithCommas(x: number) {
  const withCommas = x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `$${withCommas}`;
}

function TableHeader({ value, pr }: { value: string; pr?: number }) {
  return (
    <H5 pl={2} pr={pr} fontWeight={500} textAlign="left">
      {value}
    </H5>
  );
}

function Cell({ value, column }: { value: string; column: any }) {
  const isFirstColumn = column.id === 'drug.drug';
  return (
    <Text
      maxWidth={360}
      minWidth={isFirstColumn ? 152 : 0}
      pl={2}
      py="1px"
      fontWeight={isFirstColumn ? 600 : 400}
      fontSize={14}
      lineHeight="24px"
      color={isFirstColumn ? 'darkestShade' : 'fullShade'}
    >
      {value}
    </Text>
  );
}

function CellWithExpander({ row, value }: { row: any; value: string }) {
  return (
    <Flex justifyContent="space-between" alignItems="center" pr="4px">
      <Text px={2} fontSize={14} lineHeight="24px" color="fullShade">
        {value}
      </Text>
      <FAIcon
        {...row.getToggleRowExpandedProps()}
        icon={faChevronDown}
        style={{
          height: 16,
          padding: 4,
          cursor: 'pointer',
          transform: `rotate(${row.isExpanded ? '180' : '0'}deg)`,
        }}
      />
    </Flex>
  );
}

function getDoseMethod(row: KnapsackItem) {
  if (row.drug.doseMethod === 'per m2') {
    return ` (${row.drug.dosePerUnit} ${row.drug.doseUnit} per m²)`;
  }
  if (row.drug.doseMethod === 'per kg') {
    return ` (${row.drug.dosePerUnit} ${row.drug.doseUnit} per kg)`;
  }
  return '';
}

export default function RegimenBreakdown({
  details,
  cycles,
}: {
  details: KnapsackItem[];
  cycles: number;
}) {
  type SwitchRef = {
    isToggled: boolean;
    setIsToggled: (value: boolean) => void;
  };
  const switchRef = useRef<SwitchRef>();
  const [, setIsToggled] = useState(false);
  const onSetSwitchRef = (ref: SwitchRef) => {
    switchRef.current = ref;
    setIsToggled(switchRef.current.isToggled);
  };
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const columns = useMemo(
    () => [
      {
        Header: <TableHeader value="Item" />,
        Cell,
        sticky: isMobile ? 'left' : '',
        id: 'drug.drug',
        accessor: (row: KnapsackItem): string =>
          `${row.drug.drug} ${Math.round(row.drug.totalDose * 100) / 100} ${
            row.drug.doseUnit
          }${getDoseMethod(row)}`,
        subCellAccessor: (row: FormularyItem) => row.formularyItem,
      },
      {
        Header: <TableHeader value="Doses" />,
        Cell,
        id: 'dosePerCycle',
        accessor: (row: KnapsackItem) =>
          row.drug.dosePerCycle *
          (row.drug.doseRate === 'per cycle' ? cycles : 1),
      },
      {
        Header: <TableHeader value="Item Qty" />,
        Cell,
        id: 'itemQtyPerCycle',
        subCellAccessor: (row: FormularyItem) =>
          row.dosePerCycle *
          row.quantityPerDose *
          (row.doseRate === 'per cycle' ? cycles : 1),
      },
      {
        Header: <TableHeader value="Base" />,
        Cell,
        id: 'basePrice',
        accessor: (row: KnapsackItem) =>
          numberWithCommas(
            row.formularyItems.reduce(
              (acc, item) =>
                acc + item.basePrice * item.quantityPerDose * item.dosePerCycle,
              0
            ) * (row.drug.doseRate === 'per cycle' ? cycles : 1)
          ),
        subCellAccessor: (row: FormularyItem) =>
          numberWithCommas(
            row.basePrice *
              row.quantityPerDose *
              row.dosePerCycle *
              (row.doseRate === 'per cycle' ? cycles : 1)
          ),
      },
      {
        Header: <TableHeader pr={2} value="Subsidised" />,
        Cell: CellWithExpander,
        SubCell: Cell,
        id: 'sdlPrice',
        accessor: (row: KnapsackItem) =>
          row.formularyItems.reduce(
            (acc, item) =>
              acc + item.basePrice * item.quantityPerDose * item.dosePerCycle,
            0
          ) !==
          row.formularyItems.reduce(
            (acc, item) =>
              acc +
              Math.min(item.sdlPrice, item.mafPrice) *
                item.quantityPerDose *
                item.dosePerCycle,
            0
          )
            ? numberWithCommas(
                row.formularyItems.reduce(
                  (acc, item) =>
                    acc +
                    Math.min(item.sdlPrice, item.mafPrice) *
                      item.quantityPerDose *
                      item.dosePerCycle,
                  0
                ) * (row.drug.doseRate === 'per cycle' ? cycles : 1)
              )
            : '-',
        subCellAccessor: (row: FormularyItem) =>
          row.basePrice !== Math.min(row.sdlPrice, row.mafPrice)
            ? numberWithCommas(
                Math.min(row.sdlPrice, row.mafPrice) *
                  row.quantityPerDose *
                  row.dosePerCycle *
                  (row.doseRate === 'per cycle' ? cycles : 1)
              )
            : '-',
      },
    ],
    [isMobile]
  );
  const tableInstance = useTable(
    { columns, data: details },
    useExpanded,
    useSticky
  ) as any;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded,
    isAllRowsExpanded,
  } = tableInstance;

  useEffect(() => {
    switchRef.current?.setIsToggled(isAllRowsExpanded);
  }, [isAllRowsExpanded]);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <H5 mb={2} mt={2} fontWeight={500} flex={1}>
          Detailed breakdown
        </H5>
        <Flex>
          <H5 mr={1} fontWeight={400} color="#697481">
            Expand all
          </H5>
          <Toggle
            switchRef={onSetSwitchRef}
            onChange={(value: boolean) => toggleAllRowsExpanded(value)}
          />
        </Flex>
      </Flex>
      <Box
        style={{
          border: '1px solid #D5D7DE',
          borderRadius: '8px',
          'overflow-x': 'auto',
        }}
      >
        <Styles>
          <table
            className="table sticky"
            style={{
              cellPadding: '16px',
              width: '100%',
              borderCollapse: 'collapse',
            }}
            {...getTableProps()}
          >
            <thead className="header">
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="body" {...getTableBodyProps()}>
              {rows.map((row: any) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <React.Fragment key={rowProps.key}>
                    <tr
                      className="tr"
                      style={{ borderTop: '1px solid #D5D7DE' }}
                      {...rowProps}
                    >
                      {row.cells.map((cell: any) => (
                        <td className="td" {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                    {row.isExpanded &&
                      row.original.formularyItems.map(
                        (item: any, index: number) => (
                          <tr
                            className="tr"
                            {...rowProps}
                            style={
                              {
                                // backgroundColor: '#F4F6F8',
                                // borderTop: '1px solid #D5D7DE',
                              }
                            }
                            key={`${rowProps.key}-expanded-${index}`}
                          >
                            {row.cells.map((cell: any, cellIndex: number) => (
                              <td
                                className="td sub-cell"
                                style={
                                  cellIndex === 0 ? { paddingLeft: '24px' } : {}
                                }
                                {...cell.getCellProps()}
                              >
                                {cell.render(
                                  cell.column.SubCell ? 'SubCell' : 'Cell',
                                  {
                                    value:
                                      cell.column.subCellAccessor &&
                                      cell.column.subCellAccessor(item),
                                    row: { ...row, original: item },
                                  }
                                )}
                              </td>
                            ))}
                          </tr>
                        )
                      )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </Styles>
      </Box>
    </>
  );
}
