const IS_PRODUCTION = () => {
  if (process.env?.TARGET_IS_PRODUCTION) return true;
  return !/staging|localhost/i.test(window.location.href);
};

const REDIRECT_PATH = '/auth/callback';
const REDIRECT_PATH_PATIENT_RETRIEVE_INFO = '/patient-retrieve-info';
const REDIRECT_PATH_PATIENT_DETAILS_CONFIRM = '/patient-confirmation';

const GQL_ENDPOINT = IS_PRODUCTION()
  ? 'https://athena.production.botmd.io/gql/'
  : 'https://athena.staging.botmd.io/gql/';

const HIPPO_GQL_ENDPOINT = IS_PRODUCTION()
  ? 'https://hippocrates.production.botmd.io/gql/'
  : 'https://hippocrates.staging.botmd.io/gql/';

// SGID
const LOGIN_PROVIDER_UID_SGID = IS_PRODUCTION()
  ? 'PAUGAw58RelJFUskNLkGF1eKPYP2zenF'
  : 'Swy1YsIxB1sP0uZWBnKOlmRiswabTppA';

const LOGIN_PROVIDER_UID_SGID_CHEMO_NRIC = IS_PRODUCTION()
  ? 'OSckKbYjWvj5WMB3yiQGTEOfX4Nbuvq5'
  : 'OSckKbYjWvj5WMB3yiQGTEOfX4Nbuvq5';

// Hospital profile
const LOGIN_PROVIDER_UID = IS_PRODUCTION()
  ? 'PAUGAw58RelJFUskNLkGF1eKPYP2zenF'
  : 'G9ksGy84lpMDOx7qQSgwvn0OsXfa6Mkb';

const LOGIN_URL = IS_PRODUCTION()
  ? 'https://login.production.botmd.io'
  : 'https://login.staging.botmd.io';

const ORGANIZATIONS = ['ncis', 'sgh', 'fleminghospital', 'ttsh', 'icc'];
const MST_TEMPLATE_UID_DOC = 'if81z9GD1oaL0WuD2QID183KapschPBY' as const;
const MST_TEMPLATE_UID_PATIENT = 'derOrZGSz8x5WAMcpDq6B02tS4vibHlY' as const;

export default {
  env: process.env.NODE_ENV,
  IS_PRODUCTION,
  cookie: {
    name: 'session',
    patientUrlParamsSesh: 'patient_url_params',
    patientDetailsSesh: 'patient_details_info',
    patientCalcCostResultSesh: 'patient_calc_results',
    maxAge: 2595599,
  }, // 30 days,
  LOGIN_PROVIDER_UID,
  LOGIN_PROVIDER_UID_SGID,
  LOGIN_PROVIDER_UID_SGID_CHEMO_NRIC,
  GQL_ENDPOINT,
  HIPPO_GQL_ENDPOINT,
  LOGIN_URL,
  ORGANIZATIONS,
  REDIRECT_PATH,
  REDIRECT_PATH_PATIENT_RETRIEVE_INFO,
  REDIRECT_PATH_PATIENT_DETAILS_CONFIRM,
  MSG_TEMPLATE_UID_DOC: MST_TEMPLATE_UID_DOC,
  MSG_TEMPLATE_UID_PATIENT: MST_TEMPLATE_UID_PATIENT,
};
