import {
  Body,
  Box,
  FAIcon,
  Flex,
  H2,
  Input,
  PrimaryButton,
  SecondaryOutlinedButton,
} from '@fivehealth/botero';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { GraphQLClient } from 'graphql-request';
import { omit } from 'lodash';
import Config from '../Config';
import botmdSuccessImg from '../assets/message-sending-complete.gif';
import loadingImg from '../assets/loading.gif';
import botmdSuccessDownloadImg from '../assets/botmd-success-medal.svg';
import {
  emailSupport,
  HospitalChemocalcShareExportInputType,
  MtsocFacilityCode,
  MtsocSchemaCodeEnum,
  PatientURLParamsType,
  validateEmail,
} from '../utils/global';
import { useAppData } from '../context/AppDataContext';
import { HOSPITAL_CHEMOCALC_DELIVER_REPORT_EMAIL_MUTATION } from '../api/queries/useHospitalChemoExportReportEmail';
import { HOSPITAL_CHEMOCALC_DELIVER_REPORT_PDF_MUTATION } from '../api/queries/useHospitalChemoExportReportPDF';

function ShareModal({
  closeModal,
  type,
}: {
  closeModal: () => void;
  type: 'email' | 'download';
}) {
  const [sending, setSending] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [emailDoctor, setEmailDoctor] = useState({ value: '', error: '' });
  const [url, setUrl] = useState();

  const [cookies] = useCookies([
    Config.cookie.name,
    Config.cookie.patientCalcCostResultSesh,
    Config.cookie.patientUrlParamsSesh,
  ]);
  const { session } = useAppData();

  const patientURLParamCookie = cookies[
    `${Config.cookie.patientUrlParamsSesh}`
  ] as PatientURLParamsType;

  const doctorParams = {
    name: patientURLParamCookie.doctor?.split('|')[0],
    uid: patientURLParamCookie.doctor?.split('|')[1],
  };

  const apiEndpoint =
    type === 'email'
      ? HOSPITAL_CHEMOCALC_DELIVER_REPORT_EMAIL_MUTATION
      : HOSPITAL_CHEMOCALC_DELIVER_REPORT_PDF_MUTATION;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (emailDoctor.value) {
        const emailError = !validateEmail(emailDoctor.value);
        if (emailError) {
          setEmailDoctor({
            ...emailDoctor,
            error: 'Doctor Email is not valid',
          });
        }
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [emailDoctor?.value]);

  const apiClient = new GraphQLClient(Config.HIPPO_GQL_ENDPOINT, {
    headers: {
      'X-SESSION': session?.uid as string,
    },
  });

  const handleSubmitEmail = () => {
    setSending(true);
    setHasError(false);

    const inputRequest = {
      email: emailDoctor.value,
      doctorUid: doctorParams.uid,
      subsidyStatus: patientURLParamCookie.subsidyStatus,
      // NOTE: Hard code facility
      healthcareFacility: MtsocFacilityCode.NuhsPolyclinic, // patientURLParamCookie.hospital,
      schemaCodes: [MtsocSchemaCodeEnum.Maf],
      cancerType: patientURLParamCookie.diagnosis,
      regimens: patientURLParamCookie.regimens,
      drugIndications: patientURLParamCookie.drugsInd,
      height: patientURLParamCookie.height,
      weight: patientURLParamCookie.weight,
    } as unknown as Partial<HospitalChemocalcShareExportInputType>;

    const inputOmit =
      type === 'download'
        ? omit({ ...inputRequest }, 'email')
        : { ...inputRequest };

    // console.log('[debugger:sharemodal] -inputOmit: ', {
    //   inputOmit,
    //   type,
    //   emailDoctor,
    // });

    apiClient
      .request(apiEndpoint, {
        input: inputOmit,
      })
      .then((res) => {
        if (
          res &&
          res?.hospitalChemocalcReportExport &&
          res?.hospitalChemocalcReportExport?.reportUrl
        ) {
          setUrl(res?.hospitalChemocalcReportExport?.reportUrl);
        }
        setSending(false);
        setIsSubmitted(true);
      })
      .catch((e) => {
        setSending(false);
        setIsSubmitted(false);
        setHasError(
          e?.response?.errors?.length > 0 || e?.response?.status === 500
        );
      });
  };

  useEffect(() => {
    if (type === 'download' && !isSubmitted) handleSubmitEmail();
  }, []);

  const renderShareEmail = () => (
    <Box>
      <Flex mb={3} justifyContent="space-between" alignItems="center">
        <H2>{isSubmitted ? '' : 'Email to hospital'}</H2>
        <Box cursor="pointer" ml={6} onClick={closeModal}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>
      {isSubmitted ? (
        <Flex flexDirection="column" alignItems="center">
          <Box as="img" width={200} src={botmdSuccessImg} />

          <Box fontSize={18} fontWeight={600} color="fullShade" mt={2}>
            Email Sent
          </Box>

          <Box
            fontSize={14}
            fontWeight={400}
            style={{ textAlign: 'center' }}
            my={2}
          >
            Please let your Doctor or Financial Counselor know to check their
            email inbox. If you encounter any issues, reach out at{' '}
            <Body
              as="u"
              color="primary"
              cursor="pointer"
              onClick={() => {
                emailSupport();
              }}
              pl="4px"
              style={{ fontSize: 14, fontWeight: 400, textDecoration: 'none' }}
            >
              support@botmd.io
            </Body>
          </Box>
          <Box mt={2}>
            <PrimaryButton
              borderRadius="8px"
              fontWeight={500}
              onClick={() => {
                setUrl(undefined);
                setEmailDoctor({ value: '', error: '' });
                setHasError(false);
                closeModal();
                closeModal();
              }}
            >
              Done
            </PrimaryButton>
          </Box>
        </Flex>
      ) : (
        <Box>
          <Flex flexDirection="column">
            <Box mt={1} mb={2} fontSize={16} fontWeight={400}>
              Your treatment cost summary will be emailed to the Healthcare
              Professional who sent you the link. By clicking send email, you
              are consenting to share this information with your healthcare
              provider.
            </Box>
          </Flex>
          {hasError && (
            <Flex flexDirection="column">
              <Box mt={1} mb={2} fontSize={16} fontWeight={400} color="red">
                Error in sending email to hospital. Please try again.
              </Box>
            </Flex>
          )}
          <Box>
            <Input
              ml="2px"
              mr="2px"
              iconPosition="left"
              icon="email"
              type="email"
              state={emailDoctor.error.length > 0 ? 'danger' : ''}
              errorLabel={emailDoctor.error}
              className="input"
              size="medium"
              placeholder="Enter Doctor Email"
              label="Doctor email address"
              labelTextProps={{
                fontSize: '12px',
                fontWeight: 600,
              }}
              value={emailDoctor.value}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setEmailDoctor({ value: e.currentTarget.value, error: '' })
              }
            />
          </Box>
          <Flex mt={3} justifyContent="right">
            <Flex>
              <SecondaryOutlinedButton
                borderRadius="8px"
                fontWeight={500}
                onClick={() => {
                  setUrl(undefined);
                  setEmailDoctor({ value: '', error: '' });
                  setHasError(false);
                  closeModal();
                }}
              >
                Cancel
              </SecondaryOutlinedButton>
              <PrimaryButton
                borderRadius="8px"
                fontWeight={500}
                ml={2}
                onClick={handleSubmitEmail}
              >
                {sending ? 'Sending...' : 'Send Email'}
              </PrimaryButton>
            </Flex>
          </Flex>
        </Box>
      )}
    </Box>
  );

  const renderShareDownload = () => {
    const handleCloseOpenFile = () => {
      setTimeout(() => {
        window.open(url, '_blank');
      }, 0);

      setTimeout(() => {
        setUrl(undefined);
        setEmailDoctor({ value: '', error: '' });
        setHasError(false);
        closeModal();
      }, 100);
    };

    const handleClose = () => {
      setIsSubmitted(true);
      setUrl(undefined);
      setEmailDoctor({ value: '', error: '' });
      setHasError(false);
      closeModal();
    };
    return (
      <Box>
        <Flex mb={3} justifyContent="space-between" alignItems="center">
          <H2>{isSubmitted ? '' : 'Download Results'}</H2>
          <Box cursor="pointer" ml={6} onClick={closeModal}>
            <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
          </Box>
        </Flex>
        {isSubmitted ? (
          <Flex flexDirection="column" alignItems="center">
            <Box as="img" width={100} src={botmdSuccessDownloadImg} />

            <Box fontSize={18} fontWeight={600} color="fullShade" mt={2}>
              Your file is now ready to open and download.
            </Box>

            <Box mt={4}>
              <PrimaryButton
                borderRadius="8px"
                fontWeight={500}
                onClick={() => {
                  handleCloseOpenFile();
                }}
              >
                Open file
              </PrimaryButton>
            </Box>
          </Flex>
        ) : (
          <Box>
            <Flex justifyContent="center" alignItem="center">
              <Flex flexDirection="column">
                <Box as="img" width={150} src={loadingImg} />
                <Box fontSize={16} mt={1} fontWeight={500} color="fullShade">
                  Generating your file...
                </Box>
              </Flex>
            </Flex>
            <Flex mt={3} justifyContent="right">
              <Flex>
                <SecondaryOutlinedButton
                  borderRadius="8px"
                  fontWeight={500}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </SecondaryOutlinedButton>
              </Flex>
            </Flex>
          </Box>
        )}
      </Box>
    );
  };

  return type === 'email' ? renderShareEmail() : renderShareDownload();
}

export default ShareModal;
