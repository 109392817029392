import {
  AccordionWidget,
  Badge,
  Body,
  Box,
  FAIcon,
  Flex,
  H4,
  H5,
  H6,
  Text,
  Tooltip,
} from '@fivehealth/botero';
import {
  faChevronDown,
  faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { isEmpty } from 'lodash';
import { useCookies } from 'react-cookie';
import { useAppData } from '../context/AppDataContext';
import Config from '../Config';
import {
  ChemocalcResult,
  convertToPositiveInt,
  PatientInfoDetailsType,
} from '../utils/global';

function numberWithCommas(x: number) {
  const withCommas = (Math.sign(x) * Math.round(Math.abs(x)))
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (withCommas.startsWith('-')) {
    return `-$${withCommas.substring(1)}`;
  }
  return `$${withCommas}`;
}

export default function FinalSummaryPatient() {
  /* eslint-disable-next-line */
  const [cookies] = useCookies([
    Config.cookie.name,
    Config.cookie.patientCalcCostResultSesh,
    Config.cookie.patientDetailsSesh,
  ]);

  const patientCostResultCookie = cookies[
    `${Config.cookie.patientCalcCostResultSesh}`
  ] as ChemocalcResult[];

  const patienDetailsCookie = cookies[
    `${Config.cookie.patientDetailsSesh}`
  ] as PatientInfoDetailsType;

  const { results } = useAppData();

  const getResults = () =>
    isEmpty(patientCostResultCookie) ? results : patientCostResultCookie;

  // console.log('[debugger:finaly-summary] - Init: ', {
  //   results,
  //   patienDetailsCookie,
  //   getResutls: getResults(),
  // });

  return (
    <AccordionWidget
      accordionProps={{ bg: 'white' }}
      data={[
        {
          id: 'test-2', // NOTE: Use as a unique key for the accordion widget
          name: 'tests',
          isOpen: true,
          data: {},
        },
      ]}
      width="100%"
      renderHeader={(data: any) => (
        <Flex flexDirection="column" bg="white">
          <Flex
            bg="white"
            justifyContent="space-between"
            flexDirection={['column', 'row']}
          >
            <Flex flexDirection="column">
              <H5 fontWeight={500} color="#697481">
                Final
              </H5>
              <H5 fontWeight={500}>Out of Pocket Costs (Estimated)</H5>
            </Flex>
            <Flex justifyContent="flex-end">
              <Flex flexDirection="column" textAlign="end">
                <H6 fontWeight={600} color="#697481">
                  Out of pocket
                </H6>
                <Badge bg="primaryWithOpacity.08">
                  <H5 fontWeight={500}>
                    {`${numberWithCommas(
                      convertToPositiveInt(
                        patienDetailsCookie.finalCost.total_oop || 0
                      )
                    )} per month`}
                  </H5>
                </Badge>
              </Flex>
              <Body ml={2}>
                <FAIcon
                  icon={faChevronDown}
                  style={{
                    height: 16,
                    transform: `rotate(${data.isOpen ? '180' : '0'}deg)`,
                  }}
                />
              </Body>
            </Flex>
          </Flex>
        </Flex>
      )}
      renderBody={() => (
        <>
          <Box
            margin="20px auto"
            height={1}
            width="100%"
            backgroundColor="#D5D7DE"
          />
          <Flex pt={1} pb={1} pl={2} pr={3} justifyContent="space-between">
            <H5 fontWeight={500} color="#697481">
              CDL Drugs
            </H5>

            <H5 fontWeight={500}>
              {numberWithCommas(
                convertToPositiveInt(
                  patienDetailsCookie.finalCost.base_cost || 0
                )
              )}
            </H5>
          </Flex>

          <Flex pt={1} pb={1} pl={2} pr={3} justifyContent="space-between">
            <Tooltip
              flex={1}
              tooltip={
                <Text color="white">
                  This is NOT exhaustive. Includes estimates on infusion
                  appointments, preparation fees and certain consumables. Does
                  NOT include other costs like consultation fees, lab tests etc.
                  Cancer services are subject to annual MediShield Life and
                  MediSave claimable amounts which are not factored here.
                </Text>
              }
              toolTipOptions={{
                placement: 'top',
              }}
              toolTipElementProps={{
                maxWidth: 275,
                px: 2,
              }}
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <Flex
                justifyContent="start"
                alignItems="flex-end"
                fontWeight={500}
                color="#697481"
                fontSize={14}
                width={[70, 300]}
              >
                Other Drugs & Services{' '}
                <FAIcon
                  icon={faQuestionCircle}
                  hover={{ opacity: 0.6 }}
                  fontSize={10}
                  background="black"
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    left: '6px',
                    top: '0px',
                  }}
                />
              </Flex>
            </Tooltip>

            <H5 fontWeight={500}>
              {' '}
              {numberWithCommas(
                convertToPositiveInt(
                  patienDetailsCookie.finalCost.base_cost_cds || 0
                )
              )}
            </H5>
          </Flex>
          <Flex pt={1} pb={1} pl={2} pr={3} justifyContent="space-between">
            <H5 fontWeight={500} color="#697481">
              Govt Subsidies
            </H5>
            <Badge type="resolve" color="#111824">
              <H5 fontWeight={500}>
                {numberWithCommas(
                  patienDetailsCookie.finalCost.maf_subsidy +
                    patienDetailsCookie.finalCost.sdl_subsidy
                )}
              </H5>
            </Badge>
          </Flex>
          <Flex pt={1} pb={1} pl={2} pr={3} justifyContent="space-between">
            <Tooltip
              tooltip={
                <Text color="white">
                  This is based on drug-specific claim limits from the MOH
                  Cancer Drug List.
                </Text>
              }
              toolTipOptions={{
                placement: 'top',
              }}
              toolTipElementProps={{
                maxWidth: 275,
                px: 2,
              }}
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <Flex
                justifyContent="start"
                alignItems="flex-end"
                fontWeight={500}
                color="#697481"
                fontSize={14}
                width={[100, 300]}
              >
                MediShield Life/Insurance{' '}
                <FAIcon
                  icon={faQuestionCircle}
                  hover={{ opacity: 0.6 }}
                  fontSize={10}
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    left: '6px',
                    top: '0px',
                  }}
                />
              </Flex>
            </Tooltip>

            <Badge type="resolve" color="#111824">
              <H5 fontWeight={500}>
                {numberWithCommas(
                  patienDetailsCookie.finalCost.mshl_ip_subsidy
                )}
              </H5>
            </Badge>
          </Flex>

          <Flex pt={1} pb={1} pl={2} pr={3} justifyContent="space-between">
            <Tooltip
              tooltip={
                <Text color="white">
                  This is based on drug-specific withdrawal limits from the MOH
                  Cancer Drug List.
                </Text>
              }
              toolTipOptions={{
                placement: 'top',
              }}
              toolTipElementProps={{
                maxWidth: 275,
                px: 2,
              }}
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <Flex
                justifyContent="start"
                alignItems="flex-end"
                fontWeight={500}
                color="#697481"
                fontSize={14}
              >
                Medisave{' '}
                <FAIcon
                  icon={faQuestionCircle}
                  hover={{ opacity: 0.6 }}
                  fontSize={10}
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    left: '6px',
                    top: '0px',
                  }}
                />
              </Flex>
            </Tooltip>

            <Badge type="resolve" color="#111824">
              <H5 fontWeight={500}>
                {numberWithCommas(patienDetailsCookie.finalCost.msv_subsidy)}
              </H5>
            </Badge>
          </Flex>

          <Flex
            pt={2}
            pb={2}
            pl={2}
            pr={3}
            borderRadius="10px"
            backgroundColor="primaryWithOpacity.08"
          >
            <Box flex={[0, 1]} />
            <H5 flex={1}>Total: Estimated Out of Pocket</H5>
            <H4 flex={1} textAlign="right">
              {`${numberWithCommas(
                convertToPositiveInt(
                  patienDetailsCookie.finalCost.total_oop || 0
                )
              )} per month`}
            </H4>
          </Flex>
        </>
      )}
      containerProps={{
        padding: '16px',
        border: '1px solid #D5D7DE',
        borderRadius: '10px',
        marginTop: '16px',
        backgroundColor: 'white',
      }}
      headerProps={{
        width: '100%',
        backgroundColor: 'white',
        border: 0,
      }}
      bodyProps={{
        backgroundColor: 'white',
        minHeight: 50,
        paddingRight: '10px',
      }}
      alternateBgColor={false}
    />
  );
}
