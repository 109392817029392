import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_SESS_BYUID = gql`
  query heimdallSessionByUid($uid: String) {
    heimdallSession(uid: $uid) {
      id
      uid
      user {
        uid
      }
      scopes
      expiresOn
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_SESS_BYUID,
  queryType: 'query',
  baseQueryKey: 'heimdallSessionByUid',
  keepPreviousData: true,
});
