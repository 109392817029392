import { Box, FAIcon, Flex, H4, PrimaryButton } from '@fivehealth/botero';
import { faCheckCircle, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { GraphQLClient } from 'graphql-request';
import { isEmpty, omit } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { GRAPHQL_DOCUMENT_USER_PROFILE_SGID } from '../api/queries/useCurrentSession';

import { HOSPITAL_CHEMOCALC } from '../api/queries/useHospitalChemocalc';
import BotmdLogo from '../assets/botmd_logo.svg';
import Config from '../Config';
import {
  CancerType,
  ChemocalcResult,
  convertToPositiveInt,
  formalizeString,
  getCitizenship,
  getDobDateFormatted,
  HospitalChemocalcInputRequestType,
  HospitalChemocalcOutputResponseType,
  maskStringValue,
  MtsocFacilityCode,
  MtsocSchemaCodeEnum,
  PatientInfoDetailsType,
  PatientURLParamsType,
  PersonalDetailsType,
  SubsidyStatus,
} from '../utils/global';
import AnimatedLoadingLottie from './AnimatedLoadingLottie';

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '8px',
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FAFBFC',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
export const StyledTableRowPaper = styled(Paper)(() => ({
  boxShadow: 'none',
  border: '1px solid #d2d2d2',
  borderRadius: 8,
}));

export const renderRow = (label: string, value: string) => (
  <StyledTableRow>
    <StyledTableCell align="left" style={{ color: '#697481', fontWeight: 600 }}>
      {label}
    </StyledTableCell>
    <StyledTableCell align="right" style={{ fontWeight: 600 }}>
      {value || '-'}
    </StyledTableCell>
  </StyledTableRow>
);

function PatientConfirmCost() {
  /* eslint-disable-next-line */
  const [cookies, setCookie] = useCookies([
    Config.cookie.name,
    Config.cookie.patientUrlParamsSesh,
  ]);

  const patientURLParamsCookie = cookies[
    `${Config.cookie.patientUrlParamsSesh}`
  ] as PatientURLParamsType;

  const [patientInfoState, setPatientInfoState] = useState<
    Partial<PatientInfoDetailsType>
  >({} as PatientInfoDetailsType);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const sessParam = new URLSearchParams(window.location.search).get('session');
  const redirectUrl = `${window.location.origin}${Config.REDIRECT_PATH}?session=${sessParam}`;

  const isPatientHasData = useCallback(
    () =>
      !isEmpty(sessParam) &&
      !isEmpty(cookies[`${Config.cookie.patientUrlParamsSesh}`]),
    [sessParam]
  );

  const onRedirect = () => {
    window.location.replace(redirectUrl);
  };

  const handleHospitalSubsidies = useCallback(
    async (objParam: PatientInfoDetailsType) => {
      const { dob } = objParam.personalDetails;
      const apiClient = new GraphQLClient(Config.HIPPO_GQL_ENDPOINT, {
        headers: {
          'X-SESSION': sessParam as string,
        },
      });
      const req: HospitalChemocalcInputRequestType = {
        subsidyStatus: patientURLParamsCookie?.subsidyStatus as SubsidyStatus, // SubsidyStatus.Subsidies,
        dateOfBirth: dob as string,
        healthcareFacility: MtsocFacilityCode.NuhsPolyclinic, // TODO: Test only
        schemaCodes: [MtsocSchemaCodeEnum.Maf],
        cancerType:
          patientURLParamsCookie?.diagnosis?.toUpperCase() as CancerType,
        regimens: patientURLParamsCookie?.regimens,
        height: Number(patientURLParamsCookie?.height),
        weight: Number(patientURLParamsCookie?.weight as number),
        drugIndications: patientURLParamsCookie?.drugsInd as any,
      };
      // console.log('[debugger:confirm-cost] - handleHospitalSubsidies input: ', {
      //   req,
      //   costVariables: patientURLParamsCookie,
      //   objParam,
      // });
      apiClient
        .request(HOSPITAL_CHEMOCALC, req)
        .then((resp: HospitalChemocalcOutputResponseType) => {
          const { hospitalChemocalc } = resp;
          const hasResults = !isEmpty(
            hospitalChemocalc?.chemocalcResults?.filter((o) => !isEmpty(o))
          );
          // console.log(
          //   '[debugger:confirm-cost] - handleHospitalSubsidies resp: ',
          //   {
          //     resp,
          //     hasResults,
          //     chemocalcResults: hospitalChemocalc?.chemocalcResults?.filter(
          //       (o) => !isEmpty(o)
          //     )?.length,
          //   }
          // );

          if (!hasResults) {
            setIsLoading(true);
            setError('No available calcutation results.');
          } else {
            const detailObj: PatientInfoDetailsType = {
              ...objParam,
              finalCost: hospitalChemocalc?.finalCost,
              insuranceDetails: {
                plan: hospitalChemocalc?.insuranceSubsidy?.ip,
                provider:
                  hospitalChemocalc?.insuranceSubsidy?.insurance_provider,
                monthlyLimit: Math.abs(
                  hospitalChemocalc?.insuranceSubsidy?.ip?.ip_cdt_monthly_limit // NOTE: BE has no field and value for this
                ),
                monthlyClaimed: Math.abs(
                  hospitalChemocalc?.insuranceSubsidy?.ip
                    ?.ip_cdt_monthly_claimed
                ),
                yearlyLimit: Math.abs(
                  hospitalChemocalc?.insuranceSubsidy?.ip?.ip_cds_yearly_limit
                ),
                yearlyBalance: Math.abs(
                  hospitalChemocalc?.insuranceSubsidy?.ip?.ip_yearly_balance
                ),
                yearlyClaimed: Math.abs(
                  hospitalChemocalc?.insuranceSubsidy?.ip?.ip_cds_yearly_claimed
                ),
                rider: hospitalChemocalc?.insuranceSubsidy?.rider,
                // NOTE: BE doesnt have the actual rider cdt monthly limit, currently it returns null
                riderMonthlyLimit: Math.abs(
                  hospitalChemocalc?.insuranceSubsidy?.rider
                    ?.rider_cdt_montly_limit
                ),
                riderYearlyLimit: Math.abs(
                  hospitalChemocalc?.insuranceSubsidy?.rider
                    ?.rider_cds_yearly_limit
                ),
                // riderNonCDLYearly: Math.abs(
                //   hospitalChemocalc?.insuranceSubsidy?.rider
                //     ?.rider_cdt_yearly_limit
                // ),
                riderYearlyClaimed: Math.abs(
                  hospitalChemocalc?.insuranceSubsidy?.rider
                    ?.rider_cds_yearly_claimed
                ),
              },

              medishieldDetails: {
                class:
                  hospitalChemocalc?.insuranceSubsidy?.mshl?.mshl_class || '-',
                limit: Number(
                  Math.abs(
                    convertToPositiveInt(
                      hospitalChemocalc?.insuranceSubsidy?.mshl
                        ?.mshl_cdt_monthly_limit //  mshl_monthly_limit
                    )
                  )
                ),
                claimed: Number(
                  Math.abs(
                    Number(
                      hospitalChemocalc?.insuranceSubsidy?.mshl
                        ?.mshl_cdt_monthly_claimed
                    )
                  )
                ),
                yearlyLimit: Number(
                  Math.abs(
                    Number(
                      // hospitalChemocalc?.insuranceSubsidy?.mshl
                      //   ?.mshl_yearly_limit
                      // NOTE: New changes
                      hospitalChemocalc?.insuranceSubsidy?.mshl
                        ?.mshl_cds_yearly_limit
                    )
                  )
                ),
                yearlyBalance: Number(
                  Math.abs(
                    Number(
                      // hospitalChemocalc?.insuranceSubsidy?.mshl
                      //   ?.mshl_yearly_limit
                      // NOTE: New changes
                      hospitalChemocalc?.insuranceSubsidy?.mshl
                        ?.mshl_yearly_balance
                    )
                  )
                ),
                yearlyClaimed: Number(
                  Math.abs(
                    Number(
                      hospitalChemocalc?.insuranceSubsidy?.mshl
                        ?.mshl_cds_yearly_claimed
                    )
                  )
                ),
              },

              medisaveDetails: {
                balance: Math.abs(
                  Number(hospitalChemocalc?.insuranceSubsidy?.msv?.msv_balance)
                ),
                claimed: Math.abs(
                  Number(
                    hospitalChemocalc?.insuranceSubsidy?.msv
                      ?.msv_cdt_monthly_claimed
                  )
                ),
                limit: Math.abs(
                  Number(
                    hospitalChemocalc?.insuranceSubsidy?.msv
                      ?.msv_cdt_monthly_limit
                  )
                ),
                yearlyClaimed: Math.abs(
                  Number(
                    hospitalChemocalc?.insuranceSubsidy?.msv
                      ?.msv_cds_yearly_claimed
                  )
                ),
                yearlyLimit: Math.abs(
                  Number(
                    hospitalChemocalc?.insuranceSubsidy?.msv
                      ?.msv_cds_yearly_limit
                  )
                ),
                yearlyBalance: Math.abs(
                  Number(hospitalChemocalc?.insuranceSubsidy?.msv?.msv_balance)
                ),
                // Math.abs(
                //   150000 // NOTE: Just hard code coz backend cant give anytinå
                // ),
              },
            };
            const slimResult: Partial<ChemocalcResult>[] =
              resp?.hospitalChemocalc?.chemocalcResults?.map((o) => ({
                ...omit(o, [
                  'regimenFormularyKnapsack',
                  'disclaimer',
                  'userInputs.drugIndications',
                ]),
              }));

            // console.log(
            //   '[debugger:confirm-cost] - handleHospitalSubsidies details: ',
            //   {
            //     detailObj,
            //     slimResult,
            //   }
            // );
            setPatientInfoState(detailObj);
            setCookie(`${Config.cookie.patientDetailsSesh}`, detailObj, {
              path: '/',
            });

            setCookie(
              `${Config.cookie.patientCalcCostResultSesh}`,
              slimResult,
              {
                path: '/',
              }
            );

            const secURLParamsObj: PatientURLParamsType = {
              ...patientURLParamsCookie,
              nric: maskStringValue(patientURLParamsCookie.nric, 1, 5),
            };
            // NOTE: WE update the cookie param to secure and mask nric
            setCookie(
              `${Config.cookie.patientUrlParamsSesh}`,
              secURLParamsObj,
              {
                path: '/',
              }
            );

            setIsLoading(false);
          }
        })
        .catch(() => {
          setError('Unable to retrieve insurance details.');
          setIsLoading(false);
        });
    },
    []
  );

  const handleUserInfo = useCallback(async () => {
    setIsLoading(true);
    const apiClient = new GraphQLClient(Config.HIPPO_GQL_ENDPOINT, {
      headers: {
        'X-SESSION': sessParam as string,
      },
    });
    apiClient
      .request(GRAPHQL_DOCUMENT_USER_PROFILE_SGID, {})
      .then(async (resp) => {
        const userInfoObj = resp?.heimdallSgidUserInfo?.userInfo;

        const cObj = {
          personalDetails: {
            token: sessParam as string,
            name: userInfoObj['myinfo.name'],
            nric: maskStringValue(
              userInfoObj['myinfo.nric_number'] || userInfoObj['myinfo.nric'],
              1,
              5
            ),
            email: userInfoObj['myinfo.email'],
            nationality: userInfoObj['myinfo.nationality'],
            residentialstatus: userInfoObj['myinfo.residentialstatus'],
            dob: `${
              userInfoObj['myinfo.date_of_birth'] ||
              userInfoObj['myinfo.date_of_bitrh']
            }T00:00:00+08:00`,
            diagnosis: patientURLParamsCookie?.diagnosis as string,
          } as PersonalDetailsType,
        };
        // console.log('[debugger:confirm-cost] - handleUserInfo resp: ', {
        //   resp,
        //   cObj,
        // });
        // NOTE: We set state and cookie partial data incase if something happens to the next api
        setPatientInfoState(cObj as Partial<PatientInfoDetailsType>);
        setCookie(`${Config.cookie.patientDetailsSesh}`, cObj, {
          path: '/',
        });
        await handleHospitalSubsidies(cObj as any);
      })
      .catch(() => {
        setError('Invalid session or patient does not exis.');
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isEmpty(patientInfoState)) {
      handleUserInfo();
    }
  }, [handleUserInfo]);

  const renderPersonalDetails = () => (
    <>
      <Flex fontWeight={600} fontSize={14} justifyContent="start" mt={4} mb={1}>
        Personal Details
      </Flex>
      <TableContainer component={StyledTableRowPaper}>
        <Table>
          <TableBody>
            {renderRow(
              'Name',
              patientInfoState?.personalDetails?.name as string
            )}
            {renderRow(
              'Patient NRIC/FIN',
              patientInfoState?.personalDetails?.nric as string
            )}
            {renderRow(
              'Date of Birth',
              !patientInfoState?.personalDetails?.dob
                ? '-'
                : getDobDateFormatted(
                    patientInfoState?.personalDetails?.dob as string
                  )
            )}
            {patientInfoState?.personalDetails?.generation &&
              renderRow(
                'Pioneer/Merdeka Generation',
                patientInfoState?.personalDetails?.generation as string
              )}
            {renderRow(
              'Citizenship status',
              getCitizenship({
                'myinfo.residentialstatus':
                  patientInfoState?.personalDetails?.residentialstatus,
              }) as string
            )}
            {renderRow(
              'Nationality',
              formalizeString(
                patientInfoState?.personalDetails?.nationality as string,
                true,
                true
              )
            )}
            {renderRow(
              'Means testing',
              (patientInfoState?.personalDetails?.means as string) || 'N/A'
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const renderInsuranceDetails = () => (
    <>
      <Flex fontWeight={600} fontSize={14} justifyContent="start" mt={4} mb={1}>
        Insurance Details
      </Flex>
      <TableContainer component={StyledTableRowPaper}>
        <Table>
          <TableBody>
            {renderRow(
              'Insurance provider',
              formalizeString(
                patientInfoState?.insuranceDetails?.provider as string,
                false,
                true
              ).toUpperCase()
            )}
            {renderRow(
              'ISP Plan',
              formalizeString(
                patientInfoState?.insuranceDetails?.plan?.ip_plan,
                false,
                true
              ).toUpperCase()
            )}
            {renderRow(
              'Rider',
              formalizeString(
                patientInfoState?.insuranceDetails?.rider?.rider_plan,
                false,
                true
              ).toUpperCase()
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <>
      <Box id="nav" display={['initial']}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          height={64}
          style={{ borderBottom: '1px solid #d8d7d7' }}
        >
          <Box ml={2} as="img" src={BotmdLogo} width={100} />

          <H4 ml={-12}>ChemoCost What</H4>
          <Flex />
        </Flex>
      </Box>

      <Box height="1300px" style={{ backgroundColor: 'rgb(244, 246, 248)' }}>
        <Flex justifyContent="center">
          <Box
            width={[340, 600]}
            height="710px"
            p={4}
            mt={4}
            mx={2}
            borderRadius={10}
            style={{ backgroundColor: 'white', border: '1px solid #d2d2d2' }}
          >
            {isLoading ? (
              <Flex
                flexDirection="column"
                height="45vh"
                justifyContent="center"
                alignItems="center"
              >
                <AnimatedLoadingLottie flexProps={{ mt: 1 }} />
              </Flex>
            ) : (
              <>
                <Flex
                  fontWeight={600}
                  fontSize={16}
                  justifyContent="center"
                  mt="8px"
                >
                  Patient Details
                </Flex>
                <Flex
                  my={4}
                  fontWeight={400}
                  fontSize={15}
                  justifyContent="center"
                  textAlign="center"
                >
                  {error && (
                    <Flex
                      backgroundColor="rgb(250 235 236)"
                      p={2}
                      borderRadius={8}
                      width="100%"
                    >
                      <FAIcon icon={faXmarkCircle} color="danger" />
                      <Box ml={2} color="danger" fontSize={14}>
                        {error}
                      </Box>
                    </Flex>
                  )}

                  {!isLoading && !error && (
                    <Flex
                      backgroundColor="#EBFAF1"
                      p={2}
                      borderRadius={8}
                      width="100%"
                    >
                      <FAIcon icon={faCheckCircle} color="success" />
                      <Box ml={2} color="#27AE60" fontSize={14}>
                        Info successfully retrieved.
                      </Box>
                    </Flex>
                  )}
                </Flex>

                {renderPersonalDetails()}
                {renderInsuranceDetails()}

                <Box mt={4} mb={2}>
                  <Flex my={2} justifyContent="center">
                    {!isLoading && !error ? (
                      <PrimaryButton
                        style={{ borderRadius: 8 }}
                        onClick={onRedirect}
                        disabled={!isPatientHasData() || isLoading}
                        width="100%"
                      >
                        {isLoading ? '...' : 'View subsidies and claims'}
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        style={{ borderRadius: 8 }}
                        onClick={() => {
                          window.history.back();
                        }}
                        disabled={!isPatientHasData() || isLoading}
                        width="100%"
                      >
                        Go back
                      </PrimaryButton>
                    )}
                  </Flex>
                </Box>
              </>
            )}
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export default PatientConfirmCost;
