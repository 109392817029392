import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

export const HOSPITAL_CHEMOCALC = gql`
  query hospitalChemocalc(
    $dateOfBirth: DateTime
    $healthcareFacility: MTSOCFacilityCode!
    $schemaCodes: [MTSOCSchemaCode]!
    $cancerType: CancerType!
    $regimens: [RegimenItemInput]!
    $subsidyStatus: SubsidyStatus!
    $height: Int
    $weight: Int
    $drugIndications: GenericScalar
  ) {
    hospitalChemocalc(
      dateOfBirth: $dateOfBirth
      healthcareFacility: $healthcareFacility
      schemaCodes: $schemaCodes
      cancerType: $cancerType
      regimens: $regimens
      subsidyStatus: $subsidyStatus
      height: $height
      weight: $weight
      drugIndications: $drugIndications
    ) {
      subsidy
      insuranceSubsidy
      chemocalcResults
      finalCost
    }
  }
`;

export default createQuery({
  gqlDocument: HOSPITAL_CHEMOCALC,
  queryType: 'query',
  baseQueryKey: 'hospitalChemocalc',
  keepPreviousData: true,
});
