import {
  ActionMenu,
  ActionMenuItem,
  Box,
  FAIcon,
  Flex,
  H4,
  H5,
  useAuth,
} from '@fivehealth/botero';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useCookies } from 'react-cookie';
import { trim } from 'lodash';
import { useModal } from '../context/ModalContext';
import Config from '../Config';
import ChangeProfilePicture from './ChangeProfilePicture.moda';
import { useAppData } from '../context/AppDataContext';
import {
  encodeURLString,
  isUserPatient,
  PatientURLParamsType,
} from '../utils/global';
import BotmdLogo from '../assets/botmd_logo.svg';

const getProfilePic = () => {
  const avatar = localStorage.getItem('chemocalc-avatar');
  return avatar ? JSON.parse(avatar) : '/avatar_grey_female.svg';
};

// const blobToBase64 = (blob) =>
//   new Promise((resolve) => {
//     const reader = new FileReader();
//     reader.onload = () => {
//       const dataUrl = reader.result;
//       resolve(dataUrl);
//     };
//     reader.readAsDataURL(blob);
//   });

// const getBase64Image = async (url) => {
//   const response = await fetch(url);
//   const blob = await response.blob();
//   const base64 = await blobToBase64(blob);
//   return base64;
// };

// const getProfilePic = (user) => {
//   if (user) {
//     const profilePhoto = _.get(user, 'profilePhoto');
//     if (profilePhoto) {
//       return profilePhoto;
//     }

//     const avatar = _.get(user, 'metadata.avatar');
//     if (avatar) {
//       return `/${avatar}.svg`;
//     }
//   }
//   return '/avatar_blue_male.svg';
// };

function UserInfo({
  userName,
  avatar,
}: {
  userName: string;
  avatar: string;
}): React.ReactElement {
  return (
    <Flex alignItems="center">
      <H5>{userName}</H5>
      <Box ml={1} mr={1} as="img" src={avatar} borderRadius="50%" width={40} />
      <FAIcon icon={faChevronDown} fontSize="18px" />
    </Flex>
  );
}

function ProfileActionMenu({
  label,
  avatar,
  setAvatar,
}: {
  label: React.ReactElement;
  avatar: string;
  setAvatar: (avatar: string) => void;
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  /* eslint-disable-next-line */
  const [cookies, setCookie, removeCookie] = useCookies([
    Config.cookie.name,
    Config.cookie.patientCalcCostResultSesh,
    Config.cookie.patientDetailsSesh,
    Config.cookie.patientUrlParamsSesh,
  ]);

  const { logout } = useAuth();
  const { session } = useAppData();

  const { openModal, closeModal } = useModal();

  const user = cookies && cookies[Config.cookie.name];

  const patientURLParamCookie = cookies[
    `${Config.cookie.patientUrlParamsSesh}`
  ] as PatientURLParamsType;

  function handleLogoutPatient() {
    const generateRetrievePatientURL = `${window.location.origin}${Config.REDIRECT_PATH_PATIENT_RETRIEVE_INFO}`;

    const url = `${generateRetrievePatientURL}?regimens=${encodeURLString(
      patientURLParamCookie.regimens
    )}&weight=${patientURLParamCookie.weight}&height=${
      patientURLParamCookie.height
    }&doctor=${encodeURIComponent(
      trim(patientURLParamCookie.doctor || '')
    )}&hospital=${encodeURIComponent(
      trim(patientURLParamCookie.hospital || '')
    )}&diagnosis=${encodeURIComponent(
      trim(patientURLParamCookie.diagnosis || '')
    )}&subsidyStatus=${encodeURIComponent(
      trim(patientURLParamCookie.subsidyStatus || '')
    )}`;
    setCookie(Config.cookie.name, {}, { path: '/' });
    removeCookie(Config.cookie.name, { path: '/' });
    removeCookie(Config.cookie.patientCalcCostResultSesh, { path: '/' });
    removeCookie(Config.cookie.patientDetailsSesh, { path: '/' });
    removeCookie(Config.cookie.patientUrlParamsSesh, { path: '/' });
    return window.location.replace(url);
  }

  function handleLogout() {
    if (isUserPatient(session)) {
      handleLogoutPatient();
    } else {
      logout();
    }
  }

  return (
    <ActionMenu label={label} ml={isMobile ? '-16px' : '0px'}>
      {!isUserPatient(session) && (
        <>
          <ActionMenuItem
            onClick={() =>
              openModal(
                <ChangeProfilePicture
                  closeModal={closeModal}
                  isUpdating={false}
                  avatar={avatar}
                  setAvatar={setAvatar}
                />
              )
            }
          >
            Change profile picture
          </ActionMenuItem>
          <ActionMenuItem>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://help.botmd.com/article/222-using-ncis-chemo-calc"
            >
              Help guide
            </a>
          </ActionMenuItem>

          <ActionMenuItem style={{ borderBottom: '1px solid #D5D7DE' }}>
            <a
              href="mailto:customersupport@botmd.io?subject=ChemoCalc v2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us
            </a>
          </ActionMenuItem>
        </>
      )}

      {user && user.isMobileApp ? null : (
        <ActionMenuItem onClick={handleLogout}>Log out</ActionMenuItem>
      )}
    </ActionMenu>
  );
}

function Header({ hospital }: { hospital: string }) {
  const [userAvatar, setUserAvatar] = useState(getProfilePic());
  const [cookies] = useCookies([Config.cookie.name]);
  const user = cookies && cookies[Config.cookie.name];

  const { session } = useAppData();

  const getName = () =>
    isUserPatient(session) ? '' : `${user?.firstName} ${user?.lastName}`;
  return (
    <>
      <Box display={['initial', 'none']}>
        <Flex justifyContent="space-between" alignItems="center" height={64}>
          {!isUserPatient(session) ? (
            <ProfileActionMenu
              setAvatar={setUserAvatar}
              avatar={userAvatar}
              label={
                <Box as="img" src={userAvatar} borderRadius="50%" width={32} />
              }
            />
          ) : (
            <Box as="img" src={BotmdLogo} width={82} />
          )}

          <H4 ml={!isUserPatient(session) ? -4 : -10}>
            {isUserPatient(session) ? '' : hospital.toUpperCase()} Treatment
            Cost Summary
          </H4>
          <Flex />
        </Flex>
      </Box>
      <Box display={['none', 'initial']}>
        <Flex py={3} justifyContent="space-between" alignItems="center">
          <Box
            as="img"
            src={isUserPatient(session) ? BotmdLogo : `/${hospital}_logo.png`}
            width={153}
          />
          <H4 ml={isUserPatient(session) ? -12 : 0}>
            {isUserPatient(session) ? '' : hospital.toUpperCase()} Treatment
            Cost Summary
          </H4>
          <ProfileActionMenu
            setAvatar={setUserAvatar}
            avatar={userAvatar}
            label={<UserInfo userName={getName()} avatar={userAvatar} />}
          />
          {
            //   !isUserPatient(session) ? (
            //   <ProfileActionMenu
            //     setAvatar={setUserAvatar}
            //     avatar={userAvatar}
            //     label={
            //       <UserInfo
            //         userName={`${user?.firstName} ${user?.lastName}`}
            //         avatar={userAvatar}
            //       />
            //     }
            //   />
            // ) : (
            //   <Box width={200} />
            // )
          }
        </Flex>
      </Box>
    </>
  );
}

export default Header;
