import React from 'react';
import {
  Flex,
  Box,
  Body,
  Text,
  H5,
  FAIcon,
  AccordionWidget,
} from '@fivehealth/botero';
import {
  faChevronDown,
  faFirstAid as headerIcon,
} from '@fortawesome/pro-regular-svg-icons';
import { uniqWith, isEqual, capitalize } from 'lodash';
import { useCookies } from 'react-cookie';
import { useAppData } from '../context/AppDataContext';
import { Cost } from './Results.types';
import {
  UserInfoType,
  PatientURLParamsType,
  formalizeString,
} from '../utils/global';
import Config from '../Config';

export default function InputSummaryTreatment({
  user,
}: {
  user: UserInfoType;
}) {
  /* eslint-disable-next-line */
  const [cookies] = useCookies([
    Config.cookie.patientUrlParamsSesh,
    Config.cookie.patientCalcCostResultSesh,
  ]);
  const patientUrlParamObj = cookies[
    `${Config.cookie.patientUrlParamsSesh}`
  ] as PatientURLParamsType;

  const { results, selectedRegimens } = useAppData();

  const getChoiceOfDrug = (resultParam: any) => {
    if (resultParam === 'branded') {
      return 'Branded';
    }
    return 'Generic or Biosimilar';
  };

  const getRegimens = () =>
    selectedRegimens?.length === 0 && patientUrlParamObj
      ? patientUrlParamObj.regimens
      : selectedRegimens.map((o) => o?.regimen);

  const getResults = () =>
    results?.length === 0 && patientUrlParamObj
      ? []
      : results.map((o) => o?.userInputs);

  const resObj = {
    institution: user?.hospital,
    diagnosis: capitalize(
      user?.diagnosis || patientUrlParamObj?.diagnosis || '-'
    ),
    regimens: getRegimens(),
  };

  function getTreatmentDetails(_results: Cost[]) {
    const mafDrugs = uniqWith(
      _results
        .map((result) =>
          result?.mafIndications?.map((mafIndication) => mafIndication.drug)
        )
        .flat(1),
      isEqual
    );
    return (
      <>
        {getChoiceOfDrug(_results).length && mafDrugs?.length > 0 ? (
          <H5 color="#697481" fontWeight={500} flex={1}>
            Additional Details
          </H5>
        ) : null}

        {!results.length && (
          <H5 color="#111824" fontStyle="italic" fontWeight={400} flex={4}>
            Please input details
          </H5>
        )}
        {
          //  getChoiceOfDrug(_results).length > 0 && <Text>{getChoiceOfDrug(_results)}</Text>
        }
        {mafDrugs.map((drug) => (
          <Text key={drug}>{`${drug}: MAF-Approved Indication?: ${
            _results
              .map((result) => result.userInputs.mafDrugs)
              .flat(1)
              .includes(drug)
              ? 'Yes'
              : 'No'
          }`}</Text>
        ))}
      </>
    );
  }

  return (
    <AccordionWidget
      data={[
        {
          id: 'test-1', // NOTE: Use as a unique key for the accordion widget
          name: 'test',
          isOpen: false,
          data: {},
        },
      ]}
      width="100%"
      renderHeader={(data: any) => (
        <Flex
          bg="white"
          justifyContent="space-between"
          backgroundColor="#E9F0FE"
        >
          <Box>
            <Flex justifyContent="space-between">
              <FAIcon
                icon={headerIcon}
                style={{
                  height: 18,
                  color: '#256BF6',
                }}
              />
              <Box pl={1} color="#256BF6" fontSize={14} fontWeight={600}>
                Treatment Details
              </Box>
            </Flex>
          </Box>
          <Box>
            <Body>
              <FAIcon
                icon={faChevronDown}
                style={{
                  height: 16,
                  color: '#256BF6',
                  transform: `rotate(${data.isOpen ? '180' : '0'}deg)`,
                }}
              />
            </Body>
          </Box>
        </Flex>
      )}
      renderBody={() => (
        <Box borderRadius={8} backgroundColor="#E9F0FE">
          <Box style={{ borderBottom: '3px solid #256BF6' }} mt={1} mb={2} />
          <Flex flexDirection="column">
            <Box fontSize={14} mb={1}>
              <H5 color="darkestShade" fontWeight={500} fontSize={12} flex={4}>
                Institution
              </H5>
              <Box mb={2} fontWeight={600} s>
                {resObj.institution || '-'}
              </Box>

              <H5 color="darkestShade" fontWeight={500} fontSize={12} flex={4}>
                Cancer Diagnosis
              </H5>
              <Box mb={2} fontWeight={600} s>
                {formalizeString(resObj.diagnosis, true, true)}
              </Box>
            </Box>

            {resObj?.regimens?.map((reg, idx) => (
              <Box fontSize={14} key={`treament_details-${idx}`}>
                <Box>
                  <H5
                    color="darkestShade"
                    fontWeight={500}
                    fontSize={12}
                    flex={4}
                  >
                    {`Treatment Regimen ${idx + 1}`}
                  </H5>
                  <Box mb={1} fontWeight={600}>
                    {reg?.regimen || '-'}
                  </Box>
                </Box>

                {getResults().length > 0 && (
                  <Box>
                    <H5
                      color="darkestShade"
                      fontWeight={500}
                      fontSize={12}
                      flex={4}
                    >
                      Choice of Drug
                    </H5>
                    <Box mb={1} fontWeight={600}>
                      {getChoiceOfDrug(
                        getResults().find((f) => f.regimen === reg?.regimen)
                          ?.brandStatus
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
          </Flex>
          {results?.length > 0 && (
            <Flex flexDirection="row" pt={2}>
              <H5 color="#111824" fontWeight={400} flex={4}>
                {getTreatmentDetails(results)}
              </H5>
            </Flex>
          )}
        </Box>
      )}
      containerProps={{
        border: 0,
      }}
      headerProps={{
        width: '100%',
        backgroundColor: 'white',
        border: 0,
      }}
      bodyProps={{ backgroundColor: 'white', minHeight: 50 }}
      alternateBgColor={false}
    />
  );
}
