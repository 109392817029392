import React from 'react';
import {
  Flex,
  Box,
  Body,
  H5,
  FAIcon,
  AccordionWidget,
} from '@fivehealth/botero';
import {
  faChevronDown,
  faFirstAid as headerIcon,
} from '@fortawesome/pro-regular-svg-icons';
import { styled } from '@mui/material/styles';
import MuiBox from '@mui/material/Box';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { useCookies } from 'react-cookie';
import {
  convertToPositiveInt,
  formalizeString,
  getGraphBalanced,
  getGraphyPercentageVal,
  numberWithCommas,
  PatientInfoDetailsType,
} from '../utils/global';
import Config from '../Config';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#256BF6' : '#178e4a',
  },
}));

export default function InputSummaryMonthlyClaims() {
  const [cookies] = useCookies([Config.cookie.patientDetailsSesh]);

  const patientInfoDetailsCookie = cookies[
    `${Config.cookie.patientDetailsSesh}`
  ] as PatientInfoDetailsType;

  // console.log('[debugger:monthlyclaims] - init: ', {
  //   patientInfoDetailsCookie,
  //   test1: getGraphyPercentageVal(199, 600),
  //   test2: getGraphyPercentageVal(600, 600),
  //   test3: getGraphyPercentageVal(500, 1000),
  //   test4: getGraphyPercentageVal(599, 600),
  // });

  const renderMonthly = () => {
    const totalPlanRiderLimit =
      convertToPositiveInt(
        patientInfoDetailsCookie?.insuranceDetails?.monthlyLimit || 0
      ) +
      convertToPositiveInt(
        patientInfoDetailsCookie?.insuranceDetails?.riderMonthlyLimit || 0
      );

    const totalPlaRiderClaimed =
      convertToPositiveInt(
        patientInfoDetailsCookie?.insuranceDetails?.monthlyClaimed ||
          (0 as number)
      ) +
      convertToPositiveInt(
        patientInfoDetailsCookie?.insuranceDetails?.rider
          ?.rider_cdt_monthly_claimed || 0
      );

    const integratedPlanAndRider = {
      provider: formalizeString(
        patientInfoDetailsCookie?.insuranceDetails?.provider,
        false,
        true
      ).toUpperCase(),
      plan: formalizeString(
        patientInfoDetailsCookie?.insuranceDetails?.plan?.ip_plan,
        false,
        true
      ).toUpperCase(),
      rider:
        patientInfoDetailsCookie?.insuranceDetails?.rider?.rider_plan?.toLowerCase() ===
        'n/a'
          ? ''
          : `(${formalizeString(
              patientInfoDetailsCookie?.insuranceDetails?.rider?.rider_plan,
              false,
              true
            ).toUpperCase()})`,
      percentage: getGraphyPercentageVal(
        totalPlaRiderClaimed,
        totalPlanRiderLimit
        // patientInfoDetailsCookie?.insuranceDetails?.monthlyLimit
      ),
      limit: numberWithCommas(totalPlanRiderLimit),
      claimed: numberWithCommas(totalPlaRiderClaimed),
      balanced: getGraphBalanced(
        totalPlanRiderLimit,
        totalPlaRiderClaimed,
        'ip'
      ),
    };

    const medishieldObj = {
      limit: numberWithCommas(
        convertToPositiveInt(patientInfoDetailsCookie?.medishieldDetails?.limit)
      ),
      claimed: numberWithCommas(
        convertToPositiveInt(
          patientInfoDetailsCookie?.medishieldDetails?.claimed
        )
      ),
      percentage: getGraphyPercentageVal(
        convertToPositiveInt(
          patientInfoDetailsCookie?.medishieldDetails?.claimed
        ),
        convertToPositiveInt(patientInfoDetailsCookie?.medishieldDetails?.limit)
      ),

      balanced: getGraphBalanced(
        patientInfoDetailsCookie?.medishieldDetails?.limit as number,
        patientInfoDetailsCookie?.medishieldDetails?.claimed as number,
        'msh'
      ),
    };

    const mediSaveObj = {
      limit: numberWithCommas(
        convertToPositiveInt(patientInfoDetailsCookie?.medisaveDetails?.limit)
      ),
      claimed: numberWithCommas(
        convertToPositiveInt(patientInfoDetailsCookie?.medisaveDetails?.claimed)
      ),
      percentage: getGraphyPercentageVal(
        convertToPositiveInt(
          patientInfoDetailsCookie?.medisaveDetails?.claimed
        ),
        convertToPositiveInt(patientInfoDetailsCookie?.medisaveDetails?.limit)
      ),
      balanced: getGraphBalanced(
        patientInfoDetailsCookie?.medisaveDetails?.limit as number,
        patientInfoDetailsCookie?.medisaveDetails?.claimed as number,
        'msv'
      ),
    };

    const hasNoInsuranceClaimable = () => {
      const isInvalid = (value?: string) => value?.toLowerCase() === 'n/a';
      return (
        isInvalid(patientInfoDetailsCookie?.insuranceDetails?.provider) &&
        isInvalid(patientInfoDetailsCookie?.insuranceDetails?.plan?.ip_plan) &&
        isInvalid(patientInfoDetailsCookie?.insuranceDetails?.rider.rider_plan)
      );
    };

    // console.log('>>>>> MONTYHLY CLAIM: ', {
    //   patientInfoDetailsCookie,
    //   integratedPlanAndRider,
    //   medishieldObj,
    //   mediSaveObj,
    //   hasNoInsuranceClaimable: hasNoInsuranceClaimable(),
    // });

    return (
      <>
        {
          // INTEGRATED SHEILD PLAN AND RIDER (MONTHLY)
        }

        {!hasNoInsuranceClaimable() && (
          <Box>
            <Box mt={2}>
              <Box mb={2} fontWeight={600} fontSize={14}>
                Integrated Shield Plan & Rider
              </Box>
              <Flex flexDirection="row">
                <H5
                  color="darkestShade"
                  fontWeight={500}
                  fontSize={12}
                  flex={4}
                >
                  Insurance provider
                </H5>
                <Box fontSize={12} mb={2} fontWeight={600} mr="4px">
                  {integratedPlanAndRider.provider}
                </Box>
              </Flex>
              <Flex flexDirection="row">
                <H5
                  color="darkestShade"
                  fontWeight={500}
                  fontSize={12}
                  flex={4}
                >
                  Plan
                </H5>
                <Box fontSize={12} mb={2} fontWeight={600} mr="4px">
                  {`${integratedPlanAndRider.plan} ${integratedPlanAndRider.rider}`}
                </Box>
              </Flex>
            </Box>

            <Box borderRadius={8} p={2} backgroundColor="white">
              <Flex flexDirection="row">
                <H5
                  color="darkestShade"
                  fontWeight={500}
                  fontSize={12}
                  flex={4}
                  mb="4px"
                >
                  Monthly limit (insurance):{' '}
                  {`${integratedPlanAndRider.limit}  / month`}
                </H5>
              </Flex>

              <Box mb={1}>
                <MuiBox sx={{ flexGrow: 1 }}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={integratedPlanAndRider.percentage}
                  />
                </MuiBox>
              </Box>

              <Flex flexDirection="row">
                <H5
                  color="darkestShade"
                  fontWeight={500}
                  fontSize={12}
                  flex={4}
                  mt="-1px"
                >
                  Claimed: {integratedPlanAndRider.claimed}
                </H5>
                <Box fontSize={12} mb={2} mt="2px" fontWeight={600}>
                  {integratedPlanAndRider.balanced}
                </Box>
              </Flex>
            </Box>

            <Box
              style={{ borderBottom: '2px solid rgb(184 184 184 / 31%)' }}
              mt={2}
              mb={2}
            />
          </Box>
        )}

        {
          // MEDISHIELD  (MONTHLY)
        }

        <Box mt={4}>
          <Box mb={2} fontWeight={600} fontSize={14}>
            Medishield
          </Box>
          <Flex flexDirection="row" mb={1}>
            <H5 color="darkestShade" fontWeight={500} fontSize={12} flex={4}>
              Monthly Limit: {`${medishieldObj.limit} / month`}
            </H5>
          </Flex>
          <Box mb={1} p={2} borderRadius={8} backgroundColor="white">
            <Box pt={1}>
              <MuiBox sx={{ flexGrow: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={medishieldObj?.percentage}
                />
              </MuiBox>
            </Box>
            <Flex flexDirection="row">
              <H5
                color="darkestShade"
                fontWeight={500}
                fontSize={12}
                flex={4}
                mt="4px"
              >
                Claimed: {medishieldObj?.claimed}
              </H5>
              <Box fontSize={12} mb={2} mt={1} fontWeight={600} s>
                {medishieldObj?.balanced}
              </Box>
            </Flex>
          </Box>
        </Box>

        <Box
          style={{ borderBottom: '2px solid rgb(184 184 184 / 31%)' }}
          mt={2}
          mb={2}
        />

        {
          // MEDISAVE (MONTHLY)
        }
        <Box mt={4}>
          <Box mb={2} fontWeight={600} fontSize={14}>
            Medisave
          </Box>
          <Flex flexDirection="row" mb={1}>
            <H5 color="darkestShade" fontWeight={500} fontSize={12} flex={4}>
              Monthly limit: {`${mediSaveObj.limit} / month`}
            </H5>
          </Flex>
          <Box mb={1} p={2} borderRadius={8} backgroundColor="white">
            <Box pt={1}>
              <MuiBox sx={{ flexGrow: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={mediSaveObj.percentage}
                />
              </MuiBox>
            </Box>
            <Flex flexDirection="row">
              <H5
                color="darkestShade"
                fontWeight={500}
                fontSize={12}
                flex={4}
                mt="4px"
              >
                Claimed: {mediSaveObj.claimed}
              </H5>
              <Box fontSize={12} mb={2} mt={1} fontWeight={600} s>
                {mediSaveObj.balanced}
              </Box>
            </Flex>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <AccordionWidget
      data={[
        {
          id: 'test-1', // NOTE: Use as a unique key for the accordion widget
          name: 'test',
          isOpen: false,
          data: {},
        },
      ]}
      width="100%"
      renderHeader={(data: any) => (
        <Flex
          bg="white"
          justifyContent="space-between"
          backgroundColor="#E9F0FE"
        >
          <Box>
            <Flex justifyContent="space-between">
              <FAIcon
                icon={headerIcon}
                style={{
                  height: 18,
                  color: '#256BF6',
                }}
              />
              <Box pl={1} color="#256BF6" fontSize={14} fontWeight={600}>
                Monthly Claims (Cancer Treatment)
              </Box>
            </Flex>
          </Box>
          <Box>
            <Body>
              <FAIcon
                icon={faChevronDown}
                style={{
                  height: 16,
                  color: '#256BF6',
                  transform: `rotate(${data.isOpen ? '180' : '0'}deg)`,
                }}
              />
            </Body>
          </Box>
        </Flex>
      )}
      renderBody={() => (
        <Box borderRadius={8} backgroundColor="#E9F0FE">
          <Box style={{ borderBottom: '3px solid #256BF6' }} mb={2} />

          {renderMonthly()}
        </Box>
      )}
      containerProps={{
        border: 0,
      }}
      headerProps={{
        width: '100%',
        backgroundColor: 'white',
        border: 0,
      }}
      bodyProps={{ backgroundColor: 'white', minHeight: 50 }}
      alternateBgColor={false}
    />
  );
}
