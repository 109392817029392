import { isEmpty } from 'lodash';
import {
  Flex,
  Box,
  Body,
  H5,
  FAIcon,
  AccordionWidget,
} from '@fivehealth/botero';
import {
  faChevronDown,
  faUser as headerIcon,
} from '@fortawesome/pro-regular-svg-icons';
import { useCookies } from 'react-cookie';
import Config from '../Config';
import { useAppData } from '../context/AppDataContext';
import {
  ChemocalcResult,
  getCitizenship,
  getDobDateFormatted,
  isUserPatient,
  PatientInfoDetailsType,
  PatientURLParamsType,
  UserInfoType,
} from '../utils/global';

export default function InputSummaryPatient({ user }: { user: UserInfoType }) {
  const { results, selectedRegimens, session } = useAppData();
  /* eslint-disable-next-line */
  const [cookies] = useCookies([Config.cookie.patientDetailsSesh]);

  const patientURLParamsObj = cookies[
    `${Config.cookie.patientUrlParamsSesh}`
  ] as PatientURLParamsType;

  const patientDetailsObj = cookies[
    `${Config.cookie.patientDetailsSesh}`
  ] as PatientInfoDetailsType;

  const patientCalcResultsObj = cookies[
    `${Config.cookie.patientCalcCostResultSesh}`
  ] as ChemocalcResult[];

  const combinedDoseMethods = new Set(
    selectedRegimens
      .map(({ regimen: selectedRegimen }) => selectedRegimen.doseMethods)
      .flat()
  );
  return (
    <AccordionWidget
      data={[
        {
          id: 'test-1', // NOTE: Use as a unique key for the accordion widget
          name: 'test',
          isOpen: false,
          data: {},
        },
      ]}
      width="100%"
      renderHeader={(data: any) => (
        <Flex
          bg="white"
          justifyContent="space-between"
          backgroundColor="#FEF7ED"
        >
          <Box>
            <Flex justifyContent="space-between">
              <FAIcon
                icon={headerIcon}
                style={{
                  height: 18,
                  color: '#F2A62D',
                }}
              />
              <Box pl={1} color="#F2A62D" fontSize={14} fontWeight={600}>
                Patient Details
              </Box>
            </Flex>
          </Box>
          <Box>
            <Body>
              <FAIcon
                icon={faChevronDown}
                style={{
                  height: 16,
                  color: '#F2A62D',
                  transform: `rotate(${data.isOpen ? '180' : '0'}deg)`,
                }}
              />
            </Body>
          </Box>
        </Flex>
      )}
      renderBody={() => (
        <Box borderRadius={8} backgroundColor="#FEF7ED">
          <Box style={{ borderBottom: '3px solid #F2A62D' }} mt={1} mb={2} />

          <Flex flexDirection="column">
            {combinedDoseMethods.size === 1 &&
            combinedDoseMethods.has('fixed') ? (
              <H5 color="#111824" fontStyle="italic" fontWeight={400} flex={4}>
                Fixed dosage regimen
              </H5>
            ) : (
              <Box fontSize={14}>
                {isUserPatient(session) && (
                  <>
                    <H5
                      color="darkestShade"
                      fontWeight={500}
                      fontSize={12}
                      flex={4}
                    >
                      Name
                    </H5>
                    <Box mb={2} fontWeight={600} s>
                      {`${user?.firstName} ${user?.lastName}`}
                    </Box>

                    <H5
                      color="darkestShade"
                      fontWeight={500}
                      fontSize={12}
                      flex={4}
                    >
                      IC
                    </H5>
                    <Box mb={2} fontWeight={600}>
                      {patientURLParamsObj?.nric ||
                        patientDetailsObj?.personalDetails?.nric ||
                        '-'}
                    </Box>

                    <H5
                      color="darkestShade"
                      fontWeight={500}
                      fontSize={12}
                      flex={4}
                    >
                      Residential Status
                    </H5>
                    <Box mb={2} fontWeight={600}>
                      {
                        getCitizenship({
                          'myinfo.residentialstatus':
                            patientDetailsObj?.personalDetails
                              ?.residentialstatus,
                        }) as string
                      }
                    </Box>

                    <H5
                      color="darkestShade"
                      fontWeight={500}
                      fontSize={12}
                      flex={4}
                    >
                      Nationality
                    </H5>
                    <Box mb={2} fontWeight={600}>
                      {patientDetailsObj?.personalDetails?.nationality}
                    </Box>

                    <H5
                      color="darkestShade"
                      fontWeight={500}
                      fontSize={12}
                      flex={4}
                    >
                      Date of Birth
                    </H5>
                    <Box mb={2} fontWeight={600}>
                      {getDobDateFormatted(
                        patientDetailsObj?.personalDetails?.dob as string
                      )}
                    </Box>

                    {patientURLParamsObj?.height && (
                      <>
                        <H5
                          color="darkestShade"
                          fontWeight={500}
                          fontSize={12}
                          flex={4}
                        >
                          Height
                        </H5>
                        <Box mb={2} fontWeight={600}>
                          {patientURLParamsObj?.height
                            ? `${patientURLParamsObj?.height || 0} cm`
                            : '-'}
                        </Box>
                      </>
                    )}

                    {patientURLParamsObj?.weight && (
                      <>
                        <H5
                          color="darkestShade"
                          fontWeight={500}
                          fontSize={12}
                          flex={4}
                        >
                          Weight
                        </H5>
                        <Box mb={2} fontWeight={600}>
                          {!isEmpty(patientURLParamsObj?.weight)
                            ? `${patientURLParamsObj?.weight || 0} kg`
                            : '-'}
                        </Box>
                      </>
                    )}

                    {!isUserPatient(session) && (
                      <>
                        <H5
                          color="darkestShade"
                          fontWeight={500}
                          fontSize={12}
                          flex={4}
                        >
                          BSA
                        </H5>
                        <Box mb={2} fontWeight={600}>
                          {patientCalcResultsObj &&
                          patientCalcResultsObj?.length > 0 &&
                          patientCalcResultsObj[0]?.userInputs?.bsa
                            ? `${patientCalcResultsObj[0]?.userInputs?.bsa?.toFixed(
                                2
                              )}m²`
                            : '-'}
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Box>
            )}
            {!isUserPatient(session) && (
              <>
                <H5
                  color="darkestShade"
                  fontWeight={500}
                  fontSize={12}
                  flex={4}
                >
                  Height
                </H5>
                <Box mb={2} fontSize={14} fontWeight={600}>
                  {results[0]?.userInputs?.height
                    ? `${results[0]?.userInputs?.height}cm`
                    : '-'}
                </Box>

                <H5
                  color="darkestShade"
                  fontWeight={500}
                  fontSize={12}
                  flex={4}
                >
                  Weight
                </H5>
                <Box mb={2} fontSize={14} fontWeight={600}>
                  {results[0]?.userInputs?.weight
                    ? `${results[0]?.userInputs?.weight || 0}kg`
                    : '-'}
                </Box>
              </>
            )}
          </Flex>
        </Box>
      )}
      containerProps={{
        border: 0,
      }}
      headerProps={{
        width: '100%',
        backgroundColor: 'white',
        border: 0,
      }}
      bodyProps={{ backgroundColor: 'white', minHeight: 50 }}
      alternateBgColor={false}
    />
  );
}
