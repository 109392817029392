import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { RegimenObject, SelectedRegimen } from '../components/Regimen.types';
import { Cost } from '../components/Results.types';
import { PatientURLParamsType, PatientVariablesType } from '../utils/global';

const initialState: {
  regimens: RegimenObject[];
  selectedRegimens: SelectedRegimen[];
  results: Cost[];
  session: any;
  loading: boolean;
  patientVariables: PatientVariablesType;
  setRegimens: (value: RegimenObject[]) => void;
  setSelectedRegimens: (value: SelectedRegimen[]) => void;
  setResults: (value: Cost[]) => void;
  setLoading: (value: boolean) => void;
  setSession: (param: any) => void;
  setPatientVariables: (param: PatientVariablesType) => void;
} = {
  regimens: [],
  selectedRegimens: [],
  results: [],
  loading: false,
  session: {},
  patientVariables: {},
  /* eslint-disable @typescript-eslint/no-unused-vars */
  setSession: (value) => {},
  setRegimens: (value) => {},
  setSelectedRegimens: (value) => {},
  setResults: (value) => {},
  setLoading: (value) => {},
  setPatientVariables: (value) => {},
};
const AppContext = createContext(initialState);

export function AppDataProvider({ children }: { children: React.ReactNode }) {
  const [appState, setAppState] = useState({
    ...initialState,
  });

  const setSession = useCallback(
    (value) => {
      if (appState?.session)
        setAppState({
          ...appState,
          session: { ...appState?.session, ...value },
        });
    },
    [appState]
  );

  const setRegimens = useCallback(
    (value) => {
      if (appState?.regimens)
        setAppState({
          ...appState,
          regimens: { ...appState?.regimens, ...value },
        });
    },
    [appState]
  );

  const setSelectedRegimens = useCallback(
    (value) => {
      setAppState({
        ...appState,
        selectedRegimens: value,
      });
    },
    [appState]
  );

  const setResults = useCallback(
    (value) => {
      setAppState({
        ...appState,
        results: value,
        loading: false, // NOTE: This is a hack to make the loading state work
      });
    },
    [appState]
  );

  const setLoading = useCallback(
    (value) => {
      setAppState({
        ...appState,
        loading: value,
      });
    },
    [appState]
  );

  const setPatientVariables = useCallback(
    (value) => {
      if (appState?.patientVariables)
        setAppState({
          ...appState,
          patientVariables: value,
          loading: false,
        });
    },
    [appState]
  );

  const contextValue = useMemo(
    () => ({
      ...appState,
      setRegimens,
      setSelectedRegimens,
      setResults,
      setLoading,
      setSession,
      setPatientVariables,
    }),
    [
      appState,
      setRegimens,
      setSelectedRegimens,
      setResults,
      setLoading,
      setSession,
      setPatientVariables,
    ]
  );

  return (
    <AppContext.Provider value={contextValue}> {children}</AppContext.Provider>
  );
}

export const useAppData = () => useContext(AppContext);
