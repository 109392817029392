import {
  Body,
  Box,
  FAIcon,
  Flex,
  H2,
  Input,
  PhoneInputField,
  PrimaryButton,
  SecondaryOutlinedButton,
} from '@fivehealth/botero';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { GraphQLClient } from 'graphql-request';
import { omit, trim, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { HOSPITAL_CHEMOCALC_DELIVER_MSG_MUTATION } from '../api/queries/useHospitalChemoDeliveryMessage';
import Config from '../Config';
import { useAppData } from '../context/AppDataContext';
import botmdSuccessImg from '../assets/botmd-success-medal.svg';
import {
  emailSupport,
  encodeURLString,
  HospitalChemocalcInputRegimensType,
  HospitalChemocalcMessageTemplateDeliverInput,
  PatientURLParamsType,
  SubsidyStatus,
  UserInfoType,
  validateEmail,
} from '../utils/global';

function SendLinkModal({ closeModal }: { closeModal: () => void }) {
  const [sending, setSending] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState({ value: '', error: '' });
  const [destination, setDestination] = useState('email');
  const [whatsapp, setWhatsApp] = useState({ value: '', error: '' });
  const [cookies] = useCookies([Config.cookie.name]);

  const user = cookies && (cookies[Config.cookie.name] as UserInfoType);

  const { results, session, selectedRegimens } = useAppData();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (destination === 'email' && email.value) {
        const emailError = !validateEmail(email.value);
        if (emailError) {
          setEmail({ ...email, error: 'Recipient Email is not valid' });
        }
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [destination, email.value]);

  const generateRetrievePatientURL = `${window.location.origin}${Config.REDIRECT_PATH_PATIENT_RETRIEVE_INFO}`;

  const getSelectedRegimensMap = useCallback(
    () =>
      selectedRegimens?.map(
        (o) =>
          ({
            regimen: o?.regimen?.regimen,
            numCycles: o?.cycles,
          } as HospitalChemocalcInputRegimensType)
      ),
    []
  );

  const getSubsidyStatus = (status: string) => {
    if (status?.toLowerCase() === 'subsidised') {
      return SubsidyStatus.Subsidies;
    }
    if (status?.toLowerCase() === 'private') {
      return SubsidyStatus.Private;
    }
    return SubsidyStatus.NonResident;
  };

  const createPatientUrlParams = React.useMemo(
    () =>
      ({
        regimens: getSelectedRegimensMap(),
        weight: user?.weight || '',
        height: user?.height || '',
        // NOTE: Lets pipe the url param to save bytes :-D
        doctor: user?.directoryProfile
          ? `${user?.fullName}|${user?.directoryProfile[0]}`
          : '',
        hospital: user?.hospitalDetail?.shortName, // MtsocFacilityCode.NuhsPolyclinic, // NOTE: Hardcode it  // user?.hospital,
        diagnosis: user?.diagnosis,
        subsidyStatus: getSubsidyStatus(
          results[0]?.userInputs?.subsidyStatus ||
            (user?.subsidyStatus as string)
        ),
        drugsInd: uniqBy(
          results.map((result) => result?.userInputs?.drugIndications).flat(1),
          'drug'
        ).map((o) => omit(o, 'indications')),
      } as PatientURLParamsType),
    []
  );

  // console.log('[debugger:sendlink] -init: ', {
  //   user,
  //   results,
  //   createPatientUrlParams,
  //   session,
  // });

  const handleSubmit = async () => {
    setSending(true);
    const apiClient = new GraphQLClient(Config.HIPPO_GQL_ENDPOINT, {
      headers: {
        'X-SESSION': session?.uid as string,
      },
    });
    let req: Partial<HospitalChemocalcMessageTemplateDeliverInput> = {};

    const url = `${generateRetrievePatientURL}?regimens=${encodeURLString(
      createPatientUrlParams.regimens
    )}&weight=${createPatientUrlParams.weight}&height=${
      createPatientUrlParams.height
    }&doctor=${encodeURIComponent(
      trim(
        user?.directoryProfile
          ? `${user?.fullName}|${user?.directoryProfile[0]}`
          : '' || ''
      )
    )}&hospital=${encodeURIComponent(
      trim(user?.hospital || '')
    )}&diagnosis=${encodeURIComponent(
      trim(createPatientUrlParams.diagnosis || '')
    )}&subsidyStatus=${encodeURIComponent(
      trim(createPatientUrlParams.subsidyStatus || '')
    )}&drugsInd=${encodeURLString(createPatientUrlParams.drugsInd)}`;
    const userVariables = [{ name: 'chemocost_url', value: url }];
    if (destination === 'email') {
      req = {
        email: email.value,
        messageTemplateUid: Config.MSG_TEMPLATE_UID_DOC,
        userVariables,
      };
    }
    if (destination === 'whatsapp') {
      req = {
        messageTemplateUid: Config.MSG_TEMPLATE_UID_DOC,
        phone: `+${whatsapp.value}`,
        userVariables,
      };
    }

    // console.log('[debugger:sendlink] - handleSubmit request: ', {
    //   req,
    //   createPatientUrlParams,
    //   user,
    //   results,
    //   url,
    // });
    await apiClient.request(HOSPITAL_CHEMOCALC_DELIVER_MSG_MUTATION, {
      input: req,
    });
    setSending(false);
    setIsSubmitted(true);
  };

  // console.log('[debugger:sendlink] - init: ', {
  //   createPatientUrlParams,
  //   destination,
  //   user,
  //   results,
  // });

  return (
    <Box>
      <Flex mb={3} justifyContent="space-between" alignItems="center">
        <H2>{isSubmitted ? '' : 'Send link to patient'}</H2>
        <Box cursor="pointer" ml={6} onClick={closeModal}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>
      {isSubmitted ? (
        <Flex flexDirection="column" alignItems="center">
          <Box as="img" width={100} src={botmdSuccessImg} />

          <Box fontSize={16} fontWeight={600} color="fullShade" mt={4}>
            Treatment Link Sent
          </Box>

          <Box
            fontSize={14}
            fontWeight={400}
            style={{ textAlign: 'center' }}
            my={2}
          >
            Your patient should receive a link. Contact
            <Body
              as="u"
              color="primary"
              cursor="pointer"
              onClick={() => {
                emailSupport();
              }}
              pl="4px"
              style={{ fontSize: 14, fontWeight: 400, textDecoration: 'none' }}
            >
              support@botmd.io
            </Body>{' '}
            if you encounter any issues.
          </Box>
          <Box mt={2}>
            <PrimaryButton
              borderRadius="8px"
              fontWeight={500}
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </PrimaryButton>
          </Box>
        </Flex>
      ) : (
        <>
          <Flex flexDirection="column">
            <Box mt={1} mb={2} fontSize={14}>
              Once you have entered your patient’s treatment details, you can
              trigger a message to your patient by Email or WhatsApp message
              using the MOH WhatsApp Business account.
            </Box>
            <Box mb={4} fontSize={14}>
              The link they receive will allow them to scan their SingPass and
              extract their MOH means subsidy tier as well as other subsidy
              information (e.g. Merdeka, Pioneer Generation) to calculate their
              out of pocket costs based on your prescribed treatment.
            </Box>
            <FormControl>
              <FormLabel id="destination">Select messaging channel</FormLabel>
              <RadioGroup
                aria-labelledby="destination"
                value={destination}
                name="destination"
                onChange={(e) => {
                  setDestination(e.target.value);
                }}
              >
                <Flex>
                  <FormControlLabel
                    sx={{
                      fontSize: 12,
                    }}
                    value="whatsapp"
                    control={<Radio />}
                    label="WhatsApp"
                  />
                  <FormControlLabel
                    sx={{
                      fontSize: 12,
                    }}
                    value="email"
                    control={<Radio />}
                    label="Email"
                  />
                </Flex>
              </RadioGroup>
            </FormControl>
            {destination === 'email' ? (
              <Input
                ml="2px"
                mr="2px"
                iconPosition="left"
                icon="email"
                type="email"
                state={email.error.length > 0 ? 'danger' : ''}
                errorLabel={email.error}
                className="input"
                size="medium"
                placeholder="Enter Recipient Email"
                label="Recipient email address"
                labelTextProps={{
                  fontSize: '12px',
                  fontWeight: 600,
                }}
                value={email.value}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setEmail({ value: e.currentTarget.value, error: '' })
                }
              />
            ) : (
              <>
                <PhoneInputField
                  country="sg"
                  value={whatsapp.value}
                  onChange={(val: any) => {
                    setWhatsApp({ value: val, error: '' });
                  }}
                  placeholder="Phone Number"
                  inputStyle={{
                    width: '100%',
                  }}
                />
                <Box fontSize={12} fontWeigth={500} color="darkestShade">
                  Please confirm that the patient has WhatsApp installed
                </Box>
              </>
            )}
          </Flex>
          <Flex mt={3} justifyContent="right">
            <Flex>
              <SecondaryOutlinedButton
                borderRadius="8px"
                fontWeight={500}
                onClick={closeModal}
              >
                Cancel
              </SecondaryOutlinedButton>
              <PrimaryButton
                borderRadius="8px"
                fontWeight={500}
                ml={2}
                onClick={handleSubmit}
                disabled={sending}
              >
                {sending ? 'Sending...' : 'Send Link'}
              </PrimaryButton>
            </Flex>
          </Flex>
        </>
      )}
    </Box>
  );
}

export default SendLinkModal;
