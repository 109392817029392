import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import React from 'react';
import { Flex, Box, Body, FAIcon, AccordionWidget } from '@fivehealth/botero';
import {
  faChevronDown,
  faFirstAid as headerIcon,
} from '@fortawesome/pro-regular-svg-icons';

import { useCookies } from 'react-cookie';
import {
  convertToPositiveInt,
  formalizeString,
  getGraphBalanced,
  numberWithCommas,
  PatientInfoDetailsType,
} from '../utils/global';
import Config from '../Config';
import { renderRow, StyledTableRowPaper } from './PatientConfirmCost';

// eslint-disable-next-line import/prefer-default-export
export function InputSummaryMediShield() {
  const [cookies] = useCookies([
    Config.cookie.patientDetailsSesh,
    Config.cookie.patientDetailsSesh,
  ]);

  const patientInfoDetailsCookie = cookies[
    `${Config.cookie.patientDetailsSesh}`
  ] as PatientInfoDetailsType;

  const patienDetailsCookie = cookies[
    `${Config.cookie.patientDetailsSesh}`
  ] as PatientInfoDetailsType;

  const hasNoInsuranceClaimable = () => {
    const isInvalid = (value?: string) => value?.toLowerCase() === 'n/a';
    return (
      isInvalid(patientInfoDetailsCookie?.insuranceDetails?.provider) &&
      isInvalid(patientInfoDetailsCookie?.insuranceDetails?.plan?.ip_plan) &&
      isInvalid(patientInfoDetailsCookie?.insuranceDetails?.rider.rider_plan)
    );
  };

  const totalPlanRiderLimit =
    convertToPositiveInt(
      patientInfoDetailsCookie?.insuranceDetails?.monthlyLimit || 0
    ) +
    convertToPositiveInt(
      patientInfoDetailsCookie?.insuranceDetails?.riderMonthlyLimit || 0
    );

  const totalPlaRiderClaimed =
    convertToPositiveInt(
      patientInfoDetailsCookie?.insuranceDetails?.monthlyClaimed ||
        (0 as number)
    ) +
    convertToPositiveInt(
      patientInfoDetailsCookie?.insuranceDetails?.rider
        ?.rider_cdt_monthly_claimed || 0
    );
  // console.log('[debugger:medishield] - init: ', {
  //   patientInfoDetailsCookie,
  //   patienDetailsCookie,
  // });
  return (
    <AccordionWidget
      data={[
        {
          id: 'test-1', // NOTE: Use as a unique key for the accordion widget
          name: 'test',
          isOpen: false,
          data: {},
        },
      ]}
      width="100%"
      renderHeader={(data: any) => (
        <Flex
          bg="white"
          justifyContent="space-between"
          backgroundColor="#EBFAF1"
        >
          <Box>
            <Flex justifyContent="space-between">
              <FAIcon
                icon={headerIcon}
                style={{
                  height: 18,
                  color: '#27AE60',
                }}
              />
              <Box pl={1} color="#27AE60" fontSize={14} fontWeight={600}>
                What is claimable from Medishield/Integrated Shield Plan?
              </Box>
            </Flex>
          </Box>
          <Box>
            <Body>
              <FAIcon
                icon={faChevronDown}
                style={{
                  height: 16,
                  color: '#27AE60',
                  transform: `rotate(${data.isOpen ? '180' : '0'}deg)`,
                }}
              />
            </Body>
          </Box>
        </Flex>
      )}
      renderBody={() => (
        <Box borderRadius={8} backgroundColor="#EBFAF1" pb={2}>
          <Box style={{ borderBottom: '3px solid #27AE60' }} mb={2} />

          {!hasNoInsuranceClaimable() && (
            <Box mt={2}>
              <Flex
                fontWeight={600}
                fontSize={14}
                justifyContent="start"
                mt={4}
                mb={1}
              >
                Insurance Claimable
              </Flex>
              <TableContainer component={StyledTableRowPaper}>
                <Table>
                  <TableBody>
                    {renderRow(
                      'Plan',
                      `${formalizeString(
                        patientInfoDetailsCookie?.insuranceDetails?.provider,
                        false,
                        true
                      ).toUpperCase()} - ${formalizeString(
                        patientInfoDetailsCookie?.insuranceDetails?.plan
                          ?.ip_plan,
                        false,
                        true
                      ).toUpperCase()}`
                    )}
                    {renderRow(
                      'Cancer Drug Treatment Claim Limit',
                      `${numberWithCommas(
                        convertToPositiveInt(
                          patientInfoDetailsCookie?.insuranceDetails
                            ?.monthlyLimit
                        )
                      )} / month`
                    )}

                    {
                      // NOTE: No need to show reason
                      //   renderRow(
                      //   'Reason',
                      //   patienDetailsCookie.finalCost.reasons
                      //     ? patienDetailsCookie.finalCost.reasons
                      //         .join()
                      //         .toUpperCase()
                      //     : '-'
                      // )
                    }
                    {renderRow(
                      'Rider',
                      formalizeString(
                        patientInfoDetailsCookie?.insuranceDetails?.rider
                          ?.rider_plan,
                        false,
                        true
                      ).toUpperCase()
                    )}
                    {renderRow(
                      'Rider Cancer Drug Treatment Claim Limit',
                      `${numberWithCommas(
                        convertToPositiveInt(
                          patientInfoDetailsCookie?.insuranceDetails
                            ?.riderMonthlyLimit
                        )
                      )} / month`
                    )}

                    {renderRow(
                      'Total Claimable Limit (ISP + Rider)',
                      `${numberWithCommas(totalPlanRiderLimit)} / month`
                    )}

                    {renderRow(
                      'Existing amount already claimed for the month',
                      `${numberWithCommas(totalPlaRiderClaimed)}`
                    )}

                    {renderRow(
                      'Balance left to claim for the month',
                      `${numberWithCommas(
                        getGraphBalanced(
                          totalPlanRiderLimit,
                          totalPlaRiderClaimed,
                          'def'
                        ) as number
                      )}`
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          <Box mt={2}>
            <Flex
              fontWeight={600}
              fontSize={14}
              justifyContent="start"
              mt={4}
              mb={1}
            >
              Medishield
            </Flex>
            <TableContainer component={StyledTableRowPaper}>
              <Table>
                <TableBody>
                  {renderRow(
                    'MediShield Policy Limit',
                    `${numberWithCommas(
                      patientInfoDetailsCookie?.medishieldDetails
                        ?.yearlyBalance || 0
                    )} / year`
                  )}
                  {renderRow(
                    'MediShield Life Claim Limit',
                    `${numberWithCommas(
                      patientInfoDetailsCookie?.medishieldDetails?.limit
                    )} / month`
                  )}
                  {renderRow(
                    'Existing amount already claimed for the month',
                    `${numberWithCommas(
                      patientInfoDetailsCookie?.medishieldDetails?.claimed
                    )}`
                  )}
                  {renderRow(
                    'Balance left to claim for the month',

                    `${numberWithCommas(
                      getGraphBalanced(
                        convertToPositiveInt(
                          patientInfoDetailsCookie?.medishieldDetails?.limit
                        ),
                        convertToPositiveInt(
                          patientInfoDetailsCookie?.medishieldDetails?.claimed
                        ),
                        'def'
                      ) as number
                    )}`
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
      containerProps={{
        border: 0,
      }}
      headerProps={{
        width: '100%',
        backgroundColor: 'white',
        border: 0,
      }}
      bodyProps={{ backgroundColor: 'white', minHeight: 50 }}
      alternateBgColor={false}
    />
  );
}

// eslint-disable-next-line import/prefer-default-export
export function InputSummaryMediSave() {
  const [cookies] = useCookies([Config.cookie.patientDetailsSesh]);

  const patientInfoDetailsCookie = cookies[
    `${Config.cookie.patientDetailsSesh}`
  ] as PatientInfoDetailsType;

  return (
    <AccordionWidget
      data={[
        {
          id: 'test-1', // NOTE: Use as a unique key for the accordion widget
          name: 'test',
          isOpen: false,
          data: {},
        },
      ]}
      width="100%"
      renderHeader={(data: any) => (
        <Flex
          bg="white"
          justifyContent="space-between"
          backgroundColor="#EBFAF1"
        >
          <Box>
            <Flex justifyContent="space-between">
              <FAIcon
                icon={headerIcon}
                style={{
                  height: 18,
                  color: '#27AE60',
                }}
              />
              <Box pl={1} color="#27AE60" fontSize={14} fontWeight={600}>
                What is claimable from Medisave?
              </Box>
            </Flex>
          </Box>
          <Box>
            <Body>
              <FAIcon
                icon={faChevronDown}
                style={{
                  height: 16,
                  color: '#27AE60',
                  transform: `rotate(${data.isOpen ? '180' : '0'}deg)`,
                }}
              />
            </Body>
          </Box>
        </Flex>
      )}
      renderBody={() => (
        <Box borderRadius={8} backgroundColor="#EBFAF1" pb={2}>
          <Box style={{ borderBottom: '3px solid #27AE60' }} mb={3} />
          <Box mt={2}>
            <TableContainer component={StyledTableRowPaper}>
              <Table>
                <TableBody>
                  {renderRow(
                    'Medisave Balance in Account',
                    `${numberWithCommas(
                      patientInfoDetailsCookie?.medisaveDetails?.balance
                    )}`
                  )}
                  {renderRow(
                    'Medisave Monthly Withdrawal Limit',
                    `${numberWithCommas(
                      patientInfoDetailsCookie?.medisaveDetails?.limit
                    )} / month`
                  )}
                  {renderRow(
                    'Existing amount already claimed for the month',
                    `${numberWithCommas(
                      patientInfoDetailsCookie?.medisaveDetails?.claimed
                    )}`
                  )}
                  {renderRow(
                    'Balance left to claim for the month',
                    `${numberWithCommas(
                      getGraphBalanced(
                        convertToPositiveInt(
                          patientInfoDetailsCookie?.medisaveDetails?.limit
                        ),
                        convertToPositiveInt(
                          patientInfoDetailsCookie?.medisaveDetails?.claimed
                        ),
                        'def'
                      ) as unknown as number

                      // convertToPositiveInt(
                      //   convertToPositiveInt(
                      //     patientInfoDetailsCookie?.medisaveDetails?.limit
                      //   ) -
                      //     convertToPositiveInt(
                      //       patientInfoDetailsCookie?.medisaveDetails?.claimed
                      //     )
                      // )
                    )}`
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
      containerProps={{
        border: 0,
      }}
      headerProps={{
        width: '100%',
        backgroundColor: 'white',
        border: 0,
      }}
      bodyProps={{ backgroundColor: 'white', minHeight: 50 }}
      alternateBgColor={false}
    />
  );
}
